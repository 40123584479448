import axios from "axios";
import lsKeys from "./components/default_values/defaultKeys";
import UserSettings from "./lib/UserSettings";

let API_ROOT;

switch (process.env.REACT_APP_ENV) {
  case "production":
    API_ROOT = "https://web.teambookapp.com/api";
    break;
  case "staging":
    API_ROOT = "https://web.teambooktest.com/api";
    break;
  case "sandbox":
    API_ROOT = "https://sandbox.teambookapp.com/api";
    break;
  default:
    API_ROOT = "http://localhost:3001/api";
    break;
}

axios.defaults.baseURL = API_ROOT;

// TODO: Use Authorization HTTP header

let paramToken;
let paramUserToken;

function updateParamTokens(token) {
  paramToken = { params: { token } };
  paramUserToken = { params: { user: { token } } };
}

updateParamTokens(localStorage.getItem("Auth-Token"));

const Auth = {
  login: async (email, password, twoFactorCode) => {
    if (twoFactorCode) {
      await axios.post(`auth/login/send_two_factor`, {
        email: email,
      });
    }

    const res = await axios.post(`auth/login`, {
      user: { email, password },
      language: localStorage.getItem("tb-lang") || "en",
    });

    if (res.status === 201) {
      const token = res.data.token;
      const language = res.data.lang;
      const role = res.data.role;

      localStorage.setItem(lsKeys.AUTH_TOKEN, token);
      localStorage.setItem(lsKeys.LANGUAGE, language);
      localStorage.setItem(lsKeys.ROLE, role);
      localStorage.setItem(lsKeys.ONBOARDING_OPENED, "false");
      localStorage.setItem(lsKeys.CAPACITY_ONBOARDING_OPENED, "false");
      localStorage.setItem(lsKeys.ACTUALS_ONBOARDING_OPENED, "false");

      updateParamTokens(token);
    }

    return res;
  },

  login_google: async (token) => {
    const res = await axios.post(`auth/login_google`, {
      code: token,
      language: localStorage.getItem("tb-lang"),
    });

    if (res.status === 201) {
      const token = res.data.token;
      const language = res.data.lang;

      localStorage.setItem("Auth-Token", token);
      localStorage.setItem("tb-lang", language);

      updateParamTokens(token);
    }

    return res;
  },

  login_azure: async (token) => {
    const res = await axios.post(`auth/login_azure`, {
      auth_token: token,
      language: localStorage.getItem("tb-lang"),
    });

    if (res.status === 201) {
      const token = res.data.token;
      const language = res.data.lang;

      localStorage.setItem("Auth-Token", token);
      localStorage.setItem("tb-lang", language);

      updateParamTokens(token);
    }

    return res;
  },

  register: async (first_name, last_name, email, password, lang, acceptedMarketing, timezone, params) => {
    const language = lang || localStorage.getItem("tb-lang") || "en";

    const res = await axios.post(`register`, {
      user: {
        first_name,
        last_name,
        email,
        password,
        password_confirmation: password,
        marketing_emails: acceptedMarketing,
        time_zone: timezone,
      },

      language: language,
      extra_parameters: params,
    });

    if (res.status === 201) {
      const token = res.data;

      localStorage.setItem("Auth-Token", token);
      localStorage.setItem(lsKeys.ONBOARDING_OPENED, "true");
      localStorage.setItem(lsKeys.CAPACITY_ONBOARDING_OPENED, "true");
      localStorage.setItem(lsKeys.ACTUALS_ONBOARDING_OPENED, "true");
      localStorage.setItem(lsKeys.LAST_PLANNER_TYPE, "planners");
      localStorage.setItem(lsKeys.ROLE, "admin");
      localStorage.setItem("default_zoom", 28);

      updateParamTokens(token);
    }

    return res;
  },

  google_register: async (code, lang, acceptedMarketing, timezone, params) => {
    const language = lang || localStorage.getItem("tb-lang") || "en";

    const res = await axios.post(`register/google`, {
      code: code,
      language: language,
      extra_parameters: params,
    });

    if (res.status === 201) {
      const token = res.data.token;

      localStorage.setItem("Auth-Token", token);
      localStorage.setItem(lsKeys.ONBOARDING_OPENED, "true");
      localStorage.setItem(lsKeys.CAPACITY_ONBOARDING_OPENED, "true");
      localStorage.setItem(lsKeys.ACTUALS_ONBOARDING_OPENED, "true");
      localStorage.setItem(lsKeys.LAST_PLANNER_TYPE, "planners");
      localStorage.setItem(lsKeys.ROLE, "admin");
      localStorage.setItem("default_zoom", 28);

      updateParamTokens(token);
    }

    return res;
  },

  validate: async (first_name, last_name, email, password, lang, acceptedMarketing, timezone) => {
    const language = lang || localStorage.getItem("tb-lang") || "en";

    const isValid = axios.post(`register/validate`, {
      user: {
        first_name,
        last_name,
        email,
        password,
        password_confirmation: password,
        marketing_emails: acceptedMarketing,
        timezone: timezone,
      },

      language: language,
    });

    return isValid;
  },

  forgot_password: (email) =>
    axios.post(`auth/forgot_password`, {
      email: email,
    }),

  update_password: (code, newPassword) =>
    axios.post(`auth/update_password`, {
      code: code,
      password: newPassword,
    }),

  send_two_factor: (email) =>
    axios.post(`auth/login/send_two_factor`, {
      email: email,
    }),

  validate_two_factor: (email, code) =>
    axios.post(`auth/login/validate_two_factor`, {
      email: email,
      code: code,
    }),
};

const Account = {
  get: () => axios.get(`account/settings`, paramToken),

  update: (account_params) =>
    axios.patch("account", {
      ...account_params,
      ...paramUserToken.params,
    }),

  delete: () => axios.delete("account", paramToken),

  update_custom_project_fields: (fields) =>
    axios.patch("account/custom_project_fields", {
      fields: fields,
      ...paramToken.params,
    }),
};

const Teams = {
  all: () => axios.get(`teams`, paramToken),

  minimal: () => axios.get(`teams/minimal`, paramToken),

  get: (id) => axios.get(`team/${id}`, paramToken),

  create: (name) => axios.post(`teams`, { name }, paramToken),

  delete_users: (teamId, userIds) =>
    axios.patch(`team/${teamId}/remove_users`, {
      user_ids: userIds,
      ...paramToken.params,
    }),

  add_users: (teamId, userIds) =>
    axios.patch(`team/${teamId}/add_users`, {
      user_ids: userIds,
      ...paramToken.params,
    }),

  update: (teamId, teamName, teamUsers) =>
    axios.patch(`team/${teamId}`, {
      team: {
        name: teamName,
        users: teamUsers,
      },
      ...paramToken.params,
    }),

  delete: (teamId) =>
    axios.patch(`team/${teamId}/delete`, {
      ...paramToken.params,
    }),

  swap_users: (teamId, firstId, secondId) =>
    axios.patch(`team/${teamId}/swap_users_position`, {
      first_user_id: firstId,
      second_user_id: secondId,
      ...paramToken.params,
    }),

  get_users: (teamId) => axios.get(`team/${teamId}/get_users`, paramToken),
};

const Bookings = {
  get: (userIds, start_date, end_date) =>
    axios.post(`bookings/get`, {
      users: userIds,
      start_date: start_date,
      end_date: end_date,
      ...paramToken.params,
    }),

  delete: (bookingIds, alsoRepetitions) =>
    axios.delete("bookings", {
      params: {
        bookings: bookingIds,
        also_repetitions: alsoRepetitions,
        ...paramToken.params,
      },
    }),

  update: (bookingIds, bookingsParams) =>
    axios.patch("bookings", {
      booking_ids: bookingIds,
      fields: bookingsParams,
      ...paramToken.params,
    }),

  create: (params, repetition) =>
    axios.post("bookings", {
      bookings: params,
      ...paramToken.params,
      repetition: repetition,
    }),

  export: (params) =>
    axios.post("bookings/export", {
      team_ids: params.team_ids,
      start_date: params.start_date,
      end_date: params.end_date,
      users: params.users,
      projects: params.projects,
      billability: params.billability,
      ...paramToken.params,
    }),

  copy: (bookingIds, userId, date) =>
    axios.post("bookings/copy", {
      user_id: userId,
      date: date,
      booking_ids: bookingIds,
      ...paramToken.params,
    }),

  delete_repetition: (bookingId) =>
    axios.patch("bookings/delete_repetition", {
      booking_id: bookingId,
      ...paramToken.params,
    }),

  revert_action: () =>
    axios.post("bookings/revert", {
      ...paramToken.params,
    }),

  suggestions: (userIds) =>
    axios.get("bookings/suggestions", {
      params: {
        user_ids: userIds,
        ...paramToken.params,
      },
    }),
};

const Profile = {
  get: async (changeTheme = false) => {
    if (!paramToken.params.token) {
      window.location.replace("/login");
    }

    const profile = await axios
      .get(`profile`, paramToken)
      .then((res) => {
        if (res.status === 200) {
          const language = res.data.language;
          const role = res.data.role;
          const created_at = res.data.created_at;

          new UserSettings(res.data.user_setting, changeTheme).setLocalstorageValues();

          localStorage.setItem("created_at", created_at);
          localStorage.setItem("tb-lang", language || "en");
          localStorage.setItem("tb-role", role);

          localStorage.setItem("Delighted-EM", res.data.email);
          localStorage.setItem("Delighted-NM", res.data.name);
        }

        if (res.data.active === false) {
          localStorage.removeItem("Auth-Token");
          window.location.replace("/login");
        }

        return res;
      })
      .catch((error) => {
        localStorage.removeItem(lsKeys.AUTH_TOKEN);
      });

    return profile;
  },
};

const Projects = {
  all: () => axios.get(`projects`, paramToken),

  active: (filterValue = null) =>
    axios.get(`projects/active`, {
      params: {
        filter_value: filterValue,
        ...paramToken.params,
      },
    }),

  active_minimal: (filterValue = null) =>
    axios.get(`projects/active/minimal`, {
      params: {
        filter_value: filterValue,
        ...paramToken.params,
      },
    }),

  minimal: (filterValue = null) => axios.get(`projects/minimal`, paramToken),

  deactivated: (filterValue = null) =>
    axios.get(`projects/deactivated`, {
      params: {
        filter_value: filterValue,
        ...paramToken.params,
      },
    }),

  create: (params) => axios.post(`projects`, { ...params, ...paramToken.params }),

  update: (id, params) =>
    axios.patch(`project/${id}`, {
      project: { ...params },
      ...paramUserToken.params,
    }),

  time_off: () => axios.get(`projects/time_off`, paramToken),

  get: (id) => axios.get(`project/${id}`, paramToken),

  activate: (projectIds) =>
    axios.patch(`projects/activate`, {
      ...paramToken.params,
      project_ids: projectIds,
    }),

  upload: (params) =>
    axios.post("projects/upload", {
      ...params,
      ...paramToken.params,
    }),

  deactivate: (projectIds) =>
    axios.patch(`projects/deactivate`, {
      ...paramToken.params,
      project_ids: projectIds,
    }),

  delete: (projectIds) =>
    axios.patch(`projects/delete`, {
      ...paramToken.params,
      project_ids: projectIds,
    }),

  in_range: (ids) =>
    axios.get(`projects/in_range`, {
      params: {
        project_ids: ids || [],
        ...paramToken.params,
      },
    }),

  projection: (url_token, date) =>
    axios.get(`projects/projection`, {
      params: {
        url: url_token,
        date: date,
      },
    }),

  export: (projIds) =>
    axios.post("projects/export", {
      ...paramToken.params,
      project_ids: projIds,
    }),

  managers: (projIds) =>
    axios.get("projects/managers", {
      params: {
        ...paramToken.params,
        project_ids: projIds,
      },
    }),

  business_units: (projIds) =>
    axios.get("projects/business_units", {
      params: {
        ...paramToken.params,
        project_ids: projIds,
      },
    }),
};

const Dashboard = {
  stats: (from, to, teams, filteredOptions, type, graphRange) =>
    axios.get("dashboard/stats", {
      params: {
        from: from,
        to: to,
        teams: teams,
        filters: filteredOptions,
        type: type,
        graph_range: graphRange,
        ...paramToken.params,
      },
    }),

  split: (from, to, teams, split_by, type, slaveDataType) =>
    axios.get("dashboard/split", {
      params: {
        from: from,
        to: to,
        teams: teams,
        split_by: split_by,
        type: type,
        slave_entity_type: slaveDataType,
        ...paramToken.params,
      },
    }),

  download_reporting: ({ from, to, split_by, teams, type, slave_entity_type }) =>
    axios.post("dashboard/download_reporting", {
      from: from,
      to: to,
      split_by: split_by,
      type: type,
      teams: teams,
      slave_entity_type: slave_entity_type,
      ...paramToken.params,
    }),
};

const Users = {
  all: (params = null) => axios.get("users", { params: { ...params, ...paramToken.params } }),

  active: () => axios.get("users/active", { params: { ...paramToken.params } }),

  deactivated: (filterValue) => axios.get("users/deactivated", { params: { ...paramToken.params, filterValue } }),

  create: (params) => axios.post("users", { ...params, ...paramToken.params }),

  update: (id, params) => axios.patch(`user/${id}`, { user: { ...params, ...paramToken.params } }),

  deactivate: (userIds) =>
    axios.patch(`users/deactivate`, {
      ...paramToken.params,
      user_ids: userIds,
    }),

  activate: (userIds) =>
    axios.patch(`users/activate`, {
      ...paramToken.params,
      user_ids: userIds,
    }),

  delete: (userIds) =>
    axios.patch(`users/delete`, {
      ...paramToken.params,
      user_ids: userIds,
    }),

  update_photo: async (formData, id) => await axios.post(`user/${id}/upload_image`, formData),

  finish_registration: async (token, password, acceptedMarketing) => {
    const res = await axios.post(`users/finish_registration`, {
      user_token: token,
      password: password,
      marketing_emails: acceptedMarketing,
    });

    if (res.status === 201) {
      const token = res.data;
      localStorage.setItem("Auth-Token", token);
      localStorage.setItem(lsKeys.ONBOARDING_OPENED, "true");
      localStorage.setItem(lsKeys.ACTUALS_ONBOARDING_OPENED, "true");
      localStorage.setItem(lsKeys.CAPACITY_ONBOARDING_OPENED, "true");
      updateParamTokens(token);
    }

    return res;
  },

  initiate_email_switch: (userId, params) =>
    axios.post(`user/${userId}/initiate_email_switch`, {
      new_email: params.new_email,
      ...paramToken.params,
    }),

  finish_email_switch: (userId, params) =>
    axios.post(`user/${userId}/finish_email_switch`, {
      ...params,
      ...paramToken.params,
    }),

  upload: (params) =>
    axios.post("users/upload", {
      ...params,
      ...paramToken.params,
    }),

  reinvite: (userId) =>
    axios.post(`user/${userId}/reinvite`, {
      ...paramToken.params,
    }),

  export: (userIds) =>
    axios.get("users/export", {
      params: {
        ...paramToken.params,
        user_ids: userIds,
      },
    }),

  send_review: (message) =>
    axios.post("users/submit_review", {
      ...paramToken.params,
      message: message,
    }),

  validate_password: (old_password) =>
    axios.get("users/validate_password", { params: { ...paramToken.params, old_password: old_password } }),

  post_settings: (params) =>
    axios.post("users/visual_settings", {
      ...params,
      ...paramToken.params,
    }),

  create_saved_filter: (filter_name, page_name, filter) =>
    axios.post("users/saved_filters", {
      page_name: page_name,
      name: filter_name,
      filter: filter,
      ...paramToken.params,
    }),

  get_saved_filters: (page_name) =>
    axios.get("users/saved_filters", {
      params: {
        page_name: page_name,
        ...paramToken.params,
      },
    }),

  update_saved_filter: (filter_id, filter_name) =>
    axios.patch("users/saved_filters", {
      filter_id: filter_id,
      filter_name: filter_name,
      ...paramToken.params,
    }),

  delete_saved_filter: (filter_id) =>
    axios.delete(`users/saved_filters`, {
      params: {
        filter_id: filter_id,
        ...paramToken.params,
      },
    }),
};

const Clients = {
  all: () => axios.get("clients", paramToken),

  create: (params) => axios.post(`clients`, { ...params, ...paramToken.params }),

  deactivate: (id) => axios.patch(`client/${id}/deactivate`, { ...paramToken.params }),

  update: (id, params) =>
    axios.patch(`client/${id}`, {
      client: {
        name: params.name,
        email: params.email,
        phone_number: params.phone_number,
      },
      ...paramUserToken.params,
    }),

  projects: (client_reference_id) =>
    axios.get(`clients/projects`, {
      params: {
        reference_id: client_reference_id,
      },
    }),
};

const Tags = {
  all: (sortBy) =>
    axios.get("tags", {
      params: {
        sort_by: sortBy,
        ...paramToken.params,
      },
    }),

  update: (id, tagName, tagColor) =>
    axios.patch(`tag/${id}`, {
      tag: {
        name: tagName,
        color: tagColor,
      },
      ...paramToken.params,
    }),

  create: (name, color) => axios.post("tags", { ...paramToken.params, color, name }),

  delete: (id) => axios.delete(`tag/${id}`, { params: { ...paramToken.params } }),
};

const Calendar = {
  link: (calendar_token) => `${API_ROOT}/calendar/${calendar_token}`,
};

const Administrators = {
  login: (params) => axios.post("administration/login", { ...params }),

  accounts: (adminToken) =>
    axios.get("administration/accounts", {
      params: { token: adminToken },
    }),

  vat: (adminToken, from, to) =>
    axios.get("administration/vat", {
      params: { token: adminToken, from: from, to: to },
    }),

  get_whats_new: (language) =>
    axios.get("administration/whats_new", {
      params: { language: language },
    }),

  latest_whats_new: () => axios.get("administration/whats_new/latest"),

  create_whats_new: (adminToken, title, description) =>
    axios.post("administration/whats_new", {
      token: adminToken,
      title: title,
      description: description,
    }),

  update_whats_new: (adminToken, id, title, description) =>
    axios.patch("administration/whats_new", {
      token: adminToken,
      id: id,
      title: title,
      description: description,
    }),

  delete_whats_new: (adminToken, id) =>
    axios.delete("administration/whats_new", {
      params: {
        token: adminToken,
        id: id,
      },
    }),

  get_feedback: (adminToken) => axios.get("administration/reviews", { params: { token: adminToken } }),

  update_feedback: (adminToken, id, status, comment) =>
    axios.patch("administration/reviews", {
      token: adminToken,
      id: id,
      status: status,
      comment: comment,
    }),

  get_tb_stats: (adminToken) => axios.get("statistics/daily", { params: { token: adminToken } }),

  get_sign_offs: (adminToken) => axios.get("statistics/sign_off", { params: { token: adminToken } }),

  create_frontend_errors: (text, url) =>
    axios.post("statistics/frontend_errors", { text: text, url: url, ...paramToken.params }),
  get_frontend_errors: (adminToken) => axios.get("statistics/frontend_errors", { params: { token: adminToken } }),

  create_sign_offs: (action_type, comment) =>
    axios.post("statistics/sign_off", {
      ...paramToken.params,
      action_type: action_type,
      comment: comment,
    }),

  get_annual_invoices: (adminToken) => axios.get("statistics/annual_invoice", { params: { token: adminToken } }),

  update_annual_invoice: (accountId, adminToken) =>
    axios.patch("statistics/annual_invoice", {
      token: adminToken,
      account_id: accountId,
    }),

  create_annual_invoice: (accountId, date, adminToken) =>
    axios.post("statistics/annual_invoice", {
      token: adminToken,
      account_id: accountId,
      billing_date: date,
    }),

  delete_annual_invoice: (accountId, adminToken) =>
    axios.delete(`statistics/annual_invoice`, {
      params: { account_id: accountId, token: adminToken },
    }),
};

const Billing = {
  client_token: () => axios.get("billing/client_token", paramToken),

  plans: () => axios.get("billing/plans", paramToken),

  credit_card: () => axios.get("billing/credit_card", paramToken),

  create_subscription: (nonce, plan_id, isActualsSelected) =>
    axios.post("billing/change_subscription", {
      nonce: nonce,
      plan_id: plan_id,
      addon_ids: isActualsSelected ? ["ACTUALS"] : [],
      ...paramToken.params,
    }),

  change_payment_method: (nonce) =>
    axios.post("billing/change_payment_method", {
      nonce: nonce,
      ...paramToken.params,
    }),

  create_customer: (
    first_name,
    last_name,
    email,
    country,
    customerVat,
    customerCityName,
    customerStreetAddress,
    customerPostalCode,
    customerAccountName
  ) =>
    axios.post("billing/create_customer", {
      first_name: first_name,
      last_name: last_name,
      email: email,
      country: country,
      vat_number: customerVat,
      company_name: customerAccountName,
      city: customerCityName,
      postal_code: customerPostalCode,
      street_address: customerStreetAddress,
      ...paramToken.params,
    }),

  get_transaction: (invoice_id) => axios.get(`billing/invoices/${invoice_id}`, paramToken),

  get_all_transactions: () => axios.get(`billing/invoices`, paramToken),

  vault_credit_card: (nonce) =>
    axios.post("billing/vault_credit_card", {
      nonce: nonce,
      ...paramToken.params,
    }),

  customer_info: () => axios.get("billing/customer_info", paramToken),

  update_customer: (params) =>
    axios.patch("billing/update_customer", {
      first_name: params.firstName,
      last_name: params.lastName,
      email: params.email,
      country: params.country,
      vat_number: params.vatNumber,
      company_name: params.companyName,
      city: params.city,
      postal_code: params.postalCode,
      street_address: params.streetAddress,
      ...paramToken.params,
    }),

  create_manual_subscription: (plan_id, isActualsSelected) =>
    axios.post("billing/create_manual_subscription", {
      plan_id: plan_id,
      addon_ids: isActualsSelected ? ["ACTUALS"] : [],
      ...paramToken.params,
    }),
};

const Public = {
  generate_token: () =>
    axios.post("public/generate_token", {
      ...paramToken.params,
    }),
};

const Coupons = {
  submit: (coupon) =>
    axios.post("coupons/submit", {
      coupon: coupon,
      ...paramToken.params,
    }),
};

const Integrations = {
  Slack: {
    init: (code) =>
      axios.post("integrations/slack/init", {
        code: code,
        ...paramToken.params,
      }),
  },

  Hubspot: {
    init: (accessToken, clientSecret) =>
      axios.post("integrations/hubspot/init", {
        api_token: accessToken,
        client_secret: clientSecret,
        ...paramToken.params,
      }),

    update: (params) =>
      axios.patch("integrations/hubspot/update", {
        ...params,
        ...paramToken.params,
      }),
  },

  Holidays: {
    get: () => axios.get("integrations/holidays", paramToken),

    countries: () => axios.get("integrations/holidays/countries", paramToken),

    create: (country, userIds, region = null) =>
      axios.post("integrations/holidays", {
        country: country,
        user_ids: userIds,
        region: region,
        ...paramToken.params,
      }),

    edit: (userIds, holidayId) =>
      axios.patch(`integrations/holidays/${holidayId}`, {
        user_ids: userIds,
        ...paramToken.params,
      }),

    delete: (holidayId) => axios.delete(`integrations/holidays/${holidayId}`, paramToken),
  },

  Satriun: {
    get_email_day: () => axios.get("integrations/satriun/email_day", paramToken),

    set_email_day: (day) =>
      axios.post("integrations/satriun/email_day", {
        day: day,
        ...paramToken.params,
      }),

    trigger_email: () =>
      axios.post("integrations/satriun/trigger_email", {
        ...paramToken.params,
      }),
  },

  Personio: {
    init: (clientId, clientSecret) =>
      axios.post("integrations/personio/init", {
        client_id: clientId,
        client_secret: clientSecret,
        ...paramToken.params,
      }),

    delete: () =>
      axios.post("integrations/personio/delete", {
        ...paramToken.params,
      }),

    get_time_off_types: () =>
      axios.get("integrations/personio/time_offs", {
        params: { ...paramToken.params },
      }),

    update_time_off_types: (timeOffTypeIds, teambookTimeOffIds) =>
      axios.patch("integrations/personio/time_offs", {
        time_off_type_ids: timeOffTypeIds,
        teambook_time_off_ids: teambookTimeOffIds,
        ...paramToken.params,
      }),
  },

  Locales: {
    update: (language, json, adminToken) =>
      axios.post("integrations/locales", {
        json: json,
        language: language,
        token: adminToken,
      }),
    insert: (key, text, adminToken) =>
      axios.post("integrations/locales/insert", {
        key: key,
        text: text,
        token: adminToken,
      }),
  },

  QuickBooks: {
    get: () =>
      axios.get("integrations/quick_books", {
        params: { ...paramToken.params },
      }),
  },

  Outlook: {
    getCalendar: () =>
      axios.get("integrations/outlook/calendar", {
        params: { ...paramToken.params },
      }),

    deleteCalendar: () => axios.delete("integrations/outlook/calendar", paramToken),

    deleteEntraSync: () => axios.delete("integrations/outlook/entra_sync", paramToken),

    getEntraGroups: () => axios.get("integrations/outlook/entra_sync/groups", paramToken),

    updateEntraSync: (type, groupId) =>
      axios.patch("integrations/outlook/entra_sync", { type: type, group_id: groupId, ...paramToken.params }),
  },
};

const Milestones = {
  create: (params) =>
    axios.post("milestones", {
      name: params.name,
      project_id: params.project_id,
      start_date: params.start_date,
      end_date: params.end_date,
      order: params.order,
      ...paramToken.params,
    }),

  update: (params) =>
    axios.patch(`milestones/${params.id}`, {
      name: params.name,
      start_date: params.start_date,
      end_date: params.end_date,
      ...paramToken.params,
    }),

  delete: (id) => axios.delete(`milestones/${id}`, paramToken),

  all: () => axios.get("milestones", paramToken),
};

const CapacityReservations = {
  create: ({ user_id, project_id, days_reserved, date }) =>
    axios.post("capacity_reservations", {
      user_id: user_id,
      project_id: project_id,
      days_reserved: days_reserved,
      date: date,
      ...paramToken.params,
    }),

  get: ({ user_ids, start_date, end_date }) =>
    axios.post("capacity_reservations/get", {
      user_ids: user_ids,
      start_date: start_date,
      end_date: end_date,
      ...paramToken.params,
    }),

  copy_planner: ({ user_ids = [], team_ids = [], project_id, start_date, end_date, kind }) =>
    axios.post("capacity_reservations/copy_planner", {
      user_ids: user_ids,
      team_ids: team_ids,
      project_id: project_id,
      start_date: start_date,
      end_date: end_date,
      kind: kind,
      ...paramToken.params,
    }),

  spread: ({ user_id, project_id, start_date, end_date, duration, kind }) =>
    axios.post("capacity_reservations/spread", {
      user_id: user_id,
      project_id: project_id,
      kind: kind,
      start_date: start_date,
      end_date: end_date,
      duration: duration,
      ...paramToken.params,
    }),

  get_project_reservations: ({ project_ids, start_date, end_date }) =>
    axios.post("project_reservations/get", {
      project_ids: project_ids,
      start_date: start_date,
      end_date: end_date,
      ...paramToken.params,
    }),

  create_project_reservations: ({ project_id, days_reserved, date }) =>
    axios.post("project_reservations", {
      project_id: project_id,
      days_reserved: days_reserved,
      date: date,
      ...paramToken.params,
    }),

  export_data: ({ from, to, projects, users, billability }) =>
    axios.post("project_reservations/export_data", {
      from: from,
      to: to,
      projects: projects,
      users: users,
      billability: billability,
      ...paramToken.params,
    }),

  spread_project_reservation: ({ project_id, start_date, end_date, duration, kind }) =>
    axios.post("project_reservations/spread", {
      project_id: project_id,
      kind: kind,
      start_date: start_date,
      end_date: end_date,
      duration: duration,
      ...paramToken.params,
    }),
};

const ActualLogs = {
  create: ({ user_id, project_id, duration, date, payroll_item_id, task_id, note }) =>
    axios.post("actual_logs", {
      user_id: user_id,
      project_id: project_id,
      duration: duration,
      payroll_item_id: payroll_item_id,
      task_id: task_id,
      date: date,
      comment: note,
      ...paramToken.params,
    }),

  get: ({ user_ids, start_date, end_date }) =>
    axios.get("actual_logs", {
      params: {
        user_ids: user_ids,
        start_date: start_date,
        end_date: end_date,
        ...paramToken.params,
      },
    }),

  update: ({ id, project_id, duration, activity_id, occupation_id, note }) =>
    axios.patch(`actual_logs/${id}`, {
      project_id: project_id,
      duration: duration,
      payroll_item_id: activity_id,
      task_id: occupation_id,
      comment: note,
      ...paramToken.params,
    }),

  delete: ({ id }) =>
    axios.delete(`actual_logs/${id}`, {
      params: { project_id: id, ...paramToken.params },
    }),

  copy_planner: ({ from, to, teams, users, kind }) =>
    axios.post("actual_logs/copy_planner", {
      from: from,
      to: to,
      team_ids: teams,
      user_ids: users,
      kind: kind,
      ...paramToken.params,
    }),

  export_logs: ({ from, to, userIds, teamIds, exportFilter }) =>
    axios.post("actual_logs/export_logs", {
      from: from,
      to: to,
      user_ids: userIds,
      team_ids: teamIds,
      export_filter: exportFilter,
      ...paramToken.params,
    }),

  create_activity: ({ name }) => axios.post("payroll_items", { name: name, ...paramToken.params }),

  get_activities: () =>
    axios.get("payroll_items", {
      params: { ...paramToken.params },
    }),

  create_occupation: ({ name }) => axios.post("tasks", { name: name, ...paramToken.params }),

  get_occupations: () =>
    axios.get("tasks", {
      params: { ...paramToken.params },
    }),

  approve_log: (ids, isApproved) =>
    axios.post(`actual_logs/approve`, {
      actual_ids: ids,
      approved: isApproved,
      ...paramToken.params,
    }),

  bulk_approve: ({ userIds, teamIds, from, to }) =>
    axios.post(`actual_logs/bulk_approve`, {
      user_ids: userIds,
      team_ids: teamIds,
      from: from,
      to: to,
      ...paramToken.params,
    }),

  upload: (params) =>
    axios.post("actual_logs/upload", {
      ...params,
      ...paramToken.params,
    }),

  export: (params) =>
    axios.post("actual_logs/export", {
      team_ids: params.team_ids,
      start_date: params.start_date,
      end_date: params.end_date,
      users: params.users,
      projects: params.projects,
      billability: params.billability,
      ...paramToken.params,
    }),
};

const Tasks = {
  create: ({ project_id, name }) =>
    axios.post("tasks", {
      project_id: project_id,
      name: name,
      ...paramToken.params,
    }),

  get: () =>
    axios.get("tasks", {
      params: {
        ...paramToken.params,
      },
    }),

  update: (task_id, name) =>
    axios.patch(`task/${task_id}`, {
      name: name,
      ...paramToken.params,
    }),

  delete: (task_id) =>
    axios.delete(`task/${task_id}`, {
      params: {
        ...paramToken.params,
      },
    }),
};

const PayrollItems = {
  create: ({ name }) =>
    axios.post("payroll_items", {
      name: name,
      ...paramToken.params,
    }),

  get: () =>
    axios.get("payroll_items", {
      params: {
        ...paramToken.params,
      },
    }),

  update: (payroll_item_id, name) =>
    axios.patch(`payroll_item/${payroll_item_id}`, {
      name: name,
      ...paramToken.params,
    }),

  delete: (payroll_item_id) =>
    axios.delete(`payroll_item/${payroll_item_id}`, {
      params: {
        ...paramToken.params,
      },
    }),
};

const CustomTranslations = {
  submit: ({ key, value, language }) =>
    axios.post("custom_translations", {
      key: key,
      value: value,
      language: language,
      ...paramToken.params,
    }),

  clear: () =>
    axios.post("custom_translations/purge", {
      ...paramToken.params,
    }),
};

const Sandbox = {
  generate: async (language) => {
    const res = await axios.post(`accounts/generate_sandbox`, { language: language });

    if (res.status === 201) {
      const token = res.data;

      localStorage.setItem(lsKeys.AUTH_TOKEN, token);
      localStorage.setItem(lsKeys.LANGUAGE, "en");
      localStorage.setItem(lsKeys.ROLE, "admin");
      localStorage.setItem(lsKeys.ONBOARDING_OPENED, "true");
      localStorage.setItem(lsKeys.CAPACITY_ONBOARDING_OPENED, "true");
      localStorage.setItem(lsKeys.ACTUALS_ONBOARDING_OPENED, "true");

      updateParamTokens(token);
    }

    return res;
  },
};

const Departments = {
  all: () => axios.get("departments", paramToken),

  create: (name, color) => axios.post(`departments`, { name, color }, paramToken),

  update: (departmentId, name, color) =>
    axios.patch(`department/${departmentId}`, {
      name: name,
      color: color,
      ...paramToken.params,
    }),

  delete: (departmentId) =>
    axios.patch(`department/${departmentId}/delete`, {
      ...paramToken.params,
    }),
};

export default {
  Auth,
  Account,
  Teams,
  Bookings,
  Projects,
  Profile,
  Dashboard,
  Users,
  Clients,
  Tags,
  Calendar,
  Administrators,
  Billing,
  Public,
  Coupons,
  updateParamTokens,
  Integrations,
  Milestones,
  CapacityReservations,
  ActualLogs,
  Tasks,
  PayrollItems,
  CustomTranslations,
  Sandbox,
  Departments,
};
