import lsKeys from "../../default_values/defaultKeys";

const filterSettings = (t) => {
  return {
    userPlanner: {
      orAndSwitch: true,
      saveFilter: true,
      filterKey: lsKeys.LAST_PLANNER_FILTERS,
      filterFields: [
        t("planning.filter_users"),
        t("planning.filter_tags"),
        t("users.department"),
        t("planning.filter_projects"),
        t("planning.filter_clients"),
        t("planning.filter_advanced"),
      ],
    },
    groupedPlanner: {
      orAndSwitch: true,
      saveFilter: true,
      filterKey: lsKeys.LAST_GROUPED_PLANNER_FILTERS,
      filterFields: [
        t("planning.filter_projects"),
        t("planning.filter_clients"),
        t("planning.filter_advanced"),
        t("projects.business_unit"),
      ],
    },
    userCapacity: {
      orAndSwitch: true,
      saveFilter: true,
      filterKey: lsKeys.LAST_CAPACITY_USERS_FILTERS,
      filterFields: [t("planning.filter_teams"), t("planning.filter_users"), t("planning.filter_tags")],
    },
    projectCapacity: {
      orAndSwitch: true,
      saveFilter: true,
      filterKey: lsKeys.LAST_CAPACITY_PROJECTS_FILTERS,
      filterFields: [
        t("planning.filter_projects"),
        t("planning.filter_clients"),
        t("projects.manager"),
        t("projects.status"),
        t("projects.business_unit"),
      ],
    },
    actuals: {
      orAndSwitch: true,
      saveFilter: true,
      filterKey: lsKeys.LAST_ACTUALS_FILTERS,
      filterFields: [
        t("planning.filter_teams"),
        t("planning.filter_users"),
        t("planning.filter_projects"),
        t("planning.filter_tags"),
      ],
    },
    dashboard: {
      orAndSwitch: true,
      saveFilter: true,
      filterKey: lsKeys.LAST_REPORTING_FILTERS,
      filterFields: [
        t("planning.filter_users"),
        t("planning.filter_tags"),
        t("planning.filter_projects"),
        t("planning.filter_teams"),
        t("planning.filter_advanced"),
      ],
    },
    reportingUsers: {
      orAndSwitch: true,
      saveFilter: true,
      filterKey: lsKeys.REPORTING_USERS_FILTER,
      filterFields: [t("planning.filter_teams"), t("planning.filter_users")],
    },
    reportingProjects: {
      orAndSwitch: true,
      saveFilter: true,
      filterKey: lsKeys.REPORTING_PROJECTS_FILTER,
      filterFields: [t("planning.filter_projects"), t("planning.filter_teams")],
    },
    reportingCapacityProjects: {
      orAndSwitch: true,
      saveFilter: true,
      filterKey: lsKeys.REPORTING_CAPACITY_PROJECTS_FILTER,
      filterFields: [
        t("planning.filter_projects"),
        t("planning.filter_clients"),
        t("projects.manager"),
        t("projects.status"),
        t("projects.business_unit"),
      ],
    },
    reportingCapacityUsers: {
      orAndSwitch: true,
      saveFilter: true,
      filterKey: lsKeys.REPORTING_CAPACITY_USERS_FILTER,
      filterFields: [
        t("planning.filter_teams"),
        t("planning.filter_users"),
        t("planning.filter_tags"),
        t("projects.business_unit"),
      ],
    },
    reportingCapacityTags: {
      orAndSwitch: true,
      saveFilter: true,
      filterKey: lsKeys.REPORTING_CAPACITY_TAGS_FILTER,
      filterFields: [t("planning.filter_tags")],
    },
    reportingComparisonProject: {
      orAndSwitch: true,
      saveFilter: true,
      filterKey: lsKeys.REPORTING_COMPARISON_PROJECTS_FILTER,
      filterFields: [t("planning.filter_projects")],
    },
    reportingComparisonUser: {
      orAndSwitch: true,
      saveFilter: true,
      filterKey: lsKeys.REPORTING_COMPARISON_USERS_FILTER,
      filterFields: [t("planning.filter_users")],
    },
    reportingComparisonTasks: {
      orAndSwitch: true,
      saveFilter: true,
      filterKey: lsKeys.REPORTING_COMPARISON_TASKS_FILTER,
      filterFields: [t("planning.filter_tasks")],
    },
    reportingOverview: {
      orAndSwitch: true,
      saveFilter: true,
      filterKey: lsKeys.REPORTING_OVERVIEW_FILTER,
      filterFields: [t("planning.filter_users"), t("planning.filter_teams")],
    },
    reportingSatOverview: {
      orAndSwitch: true,
      saveFilter: true,
      filterKey: lsKeys.REPORTING_SAT_OVERVIEW_FILTER,
      filterFields: [t("planning.filter_users"), t("planning.filter_teams")],
    },
    reportingActualsUsers: {
      orAndSwitch: true,
      saveFilter: true,
      filterKey: lsKeys.REPORTING_ACTUALS_USER_FILTER,
      filterFields: [t("planning.filter_teams"), t("planning.filter_users")],
    },
    reportingActualsProjects: {
      orAndSwitch: true,
      saveFilter: true,
      filterKey: lsKeys.REPORTING_ACTUALS_PROJECT_FILTER,
      filterFields: [t("planning.filter_projects"), t("planning.filter_teams")],
    },
    reportingActualsOverview: {
      orAndSwitch: true,
      saveFilter: true,
      filterKey: lsKeys.REPORTING_ACTUALS_OVERVIEW_FILTER,
      filterFields: [t("planning.filter_teams"), t("planning.filter_users")],
    },
    usersPage: {
      orAndSwitch: true,
      saveFilter: true,
      filterKey: lsKeys.USERS_PAGE_FILTERS,
      filterFields: [
        t("planning.filter_users"),
        t("planning.filter_teams"),
        t("planning.filter_tags"),
        t("users.department"),
        t("planning.filter_authorization"),
        t("planning.filter_billability"),
      ],
    },
    projectsPage: {
      orAndSwitch: true,
      saveFilter: true,
      filterKey: lsKeys.PROJECTS_PAGE_FILTERS,
      filterFields: [
        t("planning.filter_projects"),
        t("planning.filter_clients"),
        t("projects.manager"),
        t("projects.status"),
        t("projects.business_unit"),
        t("projects.type"),
      ],
    },
  };
};

export default filterSettings;
