import { ClickAwayListener } from "@mui/base";
import { forwardRef, useImperativeHandle, useState } from "react";

const EntityTooltip = forwardRef(({ hoverableData }, ref) => {
  const [open, setOpen] = useState(false);
  const [pos, setPos] = useState({ x: null, y: null });

  useImperativeHandle(ref, () => ({
    open: open,
    setOpen: setOpen,
    pos: pos,
    setPos: setPos,
  }));

  return (
    open && (
      <ClickAwayListener
        onClickAway={() => {
          setOpen(false);
        }}
      >
        <div
          ref={ref}
          style={{
            position: "fixed",
            left: pos.x,
            top: pos.y,
            zIndex: "1500",
          }}
          id="tooltip"
        >
          <div className="tags-tooltip">
            <div
              style={{
                maxHeight: 120,
                gap: 10,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {hoverableData}
            </div>
          </div>
        </div>
      </ClickAwayListener>
    )
  );
});

export default EntityTooltip;
