import React, { useEffect, useState, useRef } from "react";
import "../../../default_styles/tb-colors.css";
import * as DefaultColors from "../../../default_values/DefaultColors";
import { useTranslation } from "react-i18next";
import FormErrorMessage from "../../../default_components/FormErrorMessage";
import { WhiteTooltip } from "./../../../default_components/Tooltips";
import TeambookColorPicker from "../../../default_components/TeambookColorPicker";
import TeambookTextForm from "../../../default_components/TeambookTextForm";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";

const findFirstFreeColor = (tags) => {
  let availableColors = [...DefaultColors.projectColors];

  tags.forEach((tag) => {
    let indexOfElement = availableColors.indexOf(tag.color);

    if (indexOfElement !== -1) {
      availableColors.splice(indexOfElement, 1);
    }
  });

  if (availableColors.length > 0) {
    return availableColors[0];
  } else {
    return DefaultColors.projectColors[0];
  }
};

function CreateTag({
  tags,
  setCreateOpen,
  createTag,
  tagName,
  setTagName,
  tagColor,
  setTagColor,
  errorMessage,
  closeErrorMessage,
}) {
  const { t } = useTranslation();
  const [changeColorsOpened, setChangeColorsOpened] = useState(false);
  const anchorRef = useRef(null);

  useEffect(() => {
    setTagColor(findFirstFreeColor(tags));
    setTagName("");
  }, []);

  const closeCreation = () => {
    setTagName("New Tag");
    setTagColor(findFirstFreeColor(tags));
    closeErrorMessage();
    setCreateOpen(false);
  };

  const changeName = (e) => {
    setTagName(e.target.value);
  };
  const handleFocus = (event) => {
    event.target.select();
  };

  return (
    <div>
      <div className="tag-menu__tag-editing">
        <div className="tag-menu__tag-add">
          <div style={{ width: 70, padding: "0px 23px" }}>
            <WhiteTooltip title={t("projects.change_color")}>
              <div
                className="tags-color-circle"
                style={{ border: `2px solid ${tagColor}`, backgroundColor: `${tagColor}66`, cursor: "pointer" }}
                onClick={() => setChangeColorsOpened(true)}
                ref={anchorRef}
              />
            </WhiteTooltip>
          </div>

          <TeambookColorPicker
            color={tagColor}
            setColor={setTagColor}
            changeColorsOpened={changeColorsOpened}
            setChangeColorsOpened={setChangeColorsOpened}
            anchorRef={anchorRef}
          />

          <div>
            <TeambookTextForm
              id={"tagName"}
              width={200}
              fieldValue={tagName}
              onChange={setTagName}
              autoFocus
              placeholder={t("users.tag_name")}
            />
          </div>

          <div className="tag-menu__tag-number">
            <p style={{ margin: 0 }}>0</p>
          </div>

          <div
            style={{
              paddingLeft: 68,
              display: "flex",
              gap: 20,
            }}
          >
            <TeambookIcon
              className={"manage-tags-icon"}
              id={"createTag"}
              name={icons.CHECK}
              alt={t("save")}
              onClick={createTag}
              tooltipTitle={t("save")}
              color="darkblue_new"
            />
            <TeambookIcon
              className={"manage-tags-icon"}
              alt={t("cancel")}
              onClick={closeCreation}
              name={icons.CROSS}
              tooltipTitle={t("cancel")}
              color="darkblue_new"
            />
          </div>
        </div>
      </div>
      <div style={{ paddingTop: errorMessage !== null ? 15 : 0 }}>
        {errorMessage !== null ? <FormErrorMessage text={errorMessage} closeErrorMessage={closeErrorMessage} /> : null}
      </div>
    </div>
  );
}

export default CreateTag;
