import React, { useEffect, useRef, useState } from "react";
import "../../../default_styles/default-style.css";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TeambookBlueButton from "../../../default_components/TeambookBlueButton";
import ProjectSelect from "../../ProjectSelect";
import { WhiteTooltip } from "../../../default_components/Tooltips";
import Slider from "@mui/material/Slider";
import RichTextEditor from "../../../default_components/rich_text_editor/RichTextEditor";
import moment from "moment";
import DeleteDialog from "../../../default_components/DeleteDialog";
import HolidayInfo from "./HolidayInfo";
import { useBookingsCreatingStore, useBookingsEditingStore, useSelectedProjectStore } from "../../../../stores/planner";
import ProjectBudget from "../../../default_components/ProjectBudget";
import { useContext } from "react";
import { PlannerContext } from "../../context/PlannerContext";
import { displayDateTime } from "../../../default_values/defaultDateFormats";
import TeambookOutlinedButton from "../../../default_components/TeambookOutlinedButton";
import { Button } from "@mui/material";
import { ClickAwayListener } from "@mui/base";
import { shallow } from "zustand/shallow";
import { TeambookCheckbox } from "../../../default_components/TeambookCheckbox";
import { InputNumber } from "../../../default_components/InputNumber";
import { NavLink } from "react-router-dom";
import { TeambookReactSelect } from "../../../default_components/TeambookReactSelect";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";
import Api from "../../../../Api";
import BookingRepetition from "../BookingRepetition";
import { useAccountStore } from "../../../../stores/accountStore";
import { useProfileStore } from "../../../../stores/profileStore";
import { parseStartTimeValue, parseStringTimeIntoInteger, StartTimeBlock } from "./StartTimeBlock";
import { ToDateString } from "../../../../lib/DateUtils";
import EditActions from "../../lib/create_edit_actions/EditActions";
import { DateTime } from "luxon";

const EditWindow = ({
  projects,
  closeEditWindow,
  deleteBookingsRequest,
  updateBookingsRequest,
  taskArray,
  setTaskArray,
  deleteRepetitionClick,
  orderedPlannerUsers,
  createBookingsRequest,
  createBookingsRef,
}) => {
  const editActions = new EditActions();

  const { t, i18n } = useTranslation();

  const [bookings, editWindowOpened] = useBookingsEditingStore(
    (state) => [state.bookingsEditing, state.editWindowOpened],
    shallow
  );
  const [account] = useAccountStore((state) => [state.account], shallow);
  const [currentUser] = useProfileStore((state) => [state.profile], shallow);

  const { selectedProject, selectedTimeOff, setSelectedProject, setSelectedTimeOff, projectsType, setProjectsType } =
    useSelectedProjectStore();
  const { users } = useContext(PlannerContext);

  const role = localStorage.getItem("tb-role") || "regular";
  const [projectId, setProjectId] = useState(editWindowOpened && bookings[0].project_id);
  const [duration, setDuration] = useState(editWindowOpened && bookings[0].duration / 60);
  const [tentative, setTentative] = useState(editWindowOpened && bookings[0].tentative);
  const [dialogWindowOpened, setDialogWindowOpened] = useState(false);
  const [deletionOpen, setDeletionOpen] = useState(false);
  const [location, setLocation] = useState(editWindowOpened && bookings[0].location);
  const [startTime, setStartTime] = useState(parseStartTimeValue(editWindowOpened && bookings[0].start_time));
  const [startTimeWindowOpened, setStartTimeWindowOpened] = useState(
    editWindowOpened && !!bookings[0].start_time?.toString()
  );
  const [usesBudget, setUsesBudget] = useState(editWindowOpened && bookings[0].using_budget);
  const [bookingTask, setBookingTask] = useState();
  const [repetitionWindowOpened, setRepetitionWindowOpened] = useState(
    editWindowOpened && !!bookings[0].repetition?.[0]
  );
  const [repetitionType, setRepetitionType] = useState(editWindowOpened && bookings[0].repetition?.[0]?.kind);
  const [repetitionEvery, setRepetitionEvery] = useState(
    editWindowOpened && {
      value: bookings[0].repetition?.[0]?.every,
      label: 1,
    }
  );
  const [repetitionEndsAt, setRepetitionEndsAt] = useState(editWindowOpened && !!bookings[0].repetition?.[0]?.ends_on);
  const [repetitionEndsAtDate, setRepetitionEndsAtDate] = useState(
    editWindowOpened && (bookings[0].repetition?.[0]?.ends_on || new Date())
  );
  const [repetitionEndsAfter, setRepetitionEndsAfter] = useState(
    editWindowOpened && !!bookings[0].repetition?.[0]?.ends_after
  );
  const [repetitionEndsAfterNumber, setRepetitionEndsAfterNumber] = useState(
    editWindowOpened && (bookings[0].repetition?.[0]?.ends_after || 1)
  );
  const [repetitionIgnoreWeekends, setRepetitionIgnoreWeekends] = useState(true);
  const [usersToCreateBookings, setUsersToCreateBookings] = useState([]);
  const [deleteBookingsAfterSave, setDeleteBookingsAfterSave] = useState(false);

  const editorRef = useRef();
  const hideTimeOffOptions = projectsType === "time_off" && !projects.some((pr) => pr.kind === "time_off");

  useEffect(() => {
    if (editWindowOpened) {
      setProjectId(bookings[0].project_id);
    }
  }, [bookings]);

  useEffect(() => {
    if (editWindowOpened) {
      document.getElementById("edit-window").focus();

      setDuration(bookings[0].duration / 60);
      setTentative(bookings[0].tentative);
      setLocation(bookings[0].location);
      setStartTime(parseStartTimeValue(bookings[0].start_time));
      setStartTimeWindowOpened(!!bookings[0].start_time?.toString());
      setUsesBudget(bookings[0].using_budget);
      setBookingTask(bookings[0].task_id);
      setRepetitionWindowOpened(!!bookings[0].repetition?.[0]);
      setRepetitionType(bookings[0].repetition?.[0]?.kind || null);
      setRepetitionEvery(
        bookings[0].repetition
          ? {
              value: bookings[0].repetition?.[0]?.every,
              label: bookings[0].repetition?.[0]?.every,
            }
          : { value: 1, label: 1 }
      );
      setRepetitionEndsAt(!!bookings[0].repetition?.[0]?.ends_on);
      setRepetitionEndsAtDate(
        bookings[0].repetition?.[0]?.ends_on
          ? new Date(bookings[0].repetition?.[0]?.ends_on)
          : new Date(bookings[0].date)
      );
      setRepetitionEndsAfter(bookings[0].repetition ? !!bookings[0].repetition?.[0]?.ends_after : true);
      setRepetitionEndsAfterNumber(bookings[0].repetition?.[0]?.ends_after || 5);
      setRepetitionIgnoreWeekends(true);
      setUsersToCreateBookings([]);
      setDeleteBookingsAfterSave(false);
    }
  }, [bookings]);

  useEffect(() => {
    if (bookings.length > 0) {
      const project_kind = projects.find((proj) => proj.id === bookings[0].project_id)?.kind || "default";

      if (project_kind === "time_off") {
        setProjectsType("time_off");
      } else {
        setProjectsType("default");
      }
    }
  }, [bookings]);

  const isEditingBlocked = () => {
    switch (role) {
      case "regular":
      case "contractor":
        return true;
      case "self_planner":
        return bookings.some(({ user_id }) => user_id !== currentUser.id);
      default:
        return false;
    }
  };

  const onKeyPress = (e) => {
    if (e.key === "Escape") {
      closeEditWindow();
    } else if (e.key === "Delete") {
      // clickOnDeleteBooking();
    }
  };

  const sliderMarks = [
    {
      value: 0,
    },
    {
      value: 1,
    },
    {
      value: 2,
    },
  ];

  const allowBookingRepetition = () => {
    return bookings.length === 1;
  };

  const changeProjectType = (newProjectType) => {
    let notSet = true;

    if (projectsType !== newProjectType && projects.size > 0) {
      projects.forEach((project) => {
        if (project.kind === "time_off" && newProjectType === "time_off" && notSet) {
          setProjectId(project.id);
          notSet = false;
        } else if (project.kind !== "time_off" && newProjectType !== "time_off" && notSet) {
          setProjectId(project.id);
          notSet = false;
        }
      });
    }

    let newProject =
      newProjectType === "time_off"
        ? projects.find((pr) => pr.kind === "time_off")
        : projects.find((pr) => pr.kind !== "time_off");

    if (newProjectType === "time_off" && selectedTimeOff === null) {
      setSelectedTimeOff(newProject);
    } else if (newProjectType === "default" && selectedProject === null) {
      setSelectedProject(newProject);
    }

    setProjectsType(newProjectType);
  };

  const nameById = (id) => {
    if (!id) {
      return "undefined";
    }

    let user = users.filter((obj) => obj.id === id)[0];

    if (!user) {
      return "undefined";
    } else {
      return `${user.first_name} ${user.last_name[0]}`;
    }
  };

  const deleteBookings = () => {
    deleteBookingsRequest(bookings);
    setDialogWindowOpened(false);
  };

  const updateBookings = async () => {
    if (account.subscription.subscription_id === "Free" && account.subscription.projects_available === 10) {
      setDialogWindowOpened(false);
    }

    if (deleteBookingsAfterSave) {
      deleteBookingsRequest(bookings);
    } else {
      updateBookingsRequest(
        duration,
        tentative,
        location,
        startTimeWindowOpened ? parseStringTimeIntoInteger(startTime) : null,
        editorRef.current.getComment() === "<p></p>" ? "" : editorRef.current.getComment(),
        usesBudget,
        null,
        null,
        bookingTask,
        {
          on: !!repetitionType,
          type: repetitionType,
          every: repetitionEvery.value || 1,
          ends_on: repetitionEndsAt ? ToDateString(repetitionEndsAtDate) : null,
          ends_after: repetitionEndsAfter ? repetitionEndsAfterNumber : null,
          ignore_weekends: repetitionIgnoreWeekends,
        }
      );

      setDialogWindowOpened(false);

      bookings.forEach(({ id, project_id }) => {
        let editBooking;

        if (document.querySelector(`#booking-${id}`)) {
          editBooking = document.querySelector(`#booking-${id}`);

          let selectedObject = editBooking.querySelector(".booking__project-duration");

          if (selectedObject) {
            selectedObject.innerHTML = duration + "h";
          }
        } else if (document.querySelector(`#booking-${id}-${project_id}`)) {
          editBooking = Array.from(document.querySelectorAll(`.booking-${id}-${project_id}`));

          editBooking.forEach((el) => {
            if (el.querySelector(".booking__project-duration")) {
              el.querySelector(".booking__project-duration").innerHTML = duration + "h";
            }
          });
        }
      });
    }

    if (usersToCreateBookings.length > 0) {
      const project =
        useSelectedProjectStore.getState().projectsType === "time_off"
          ? useSelectedProjectStore.getState().selectedTimeOff
          : useSelectedProjectStore.getState().selectedProject;

      await new Promise((resolve) => {
        const newBookings = usersToCreateBookings
          .map((user) => {
            const userIndex = orderedPlannerUsers.findIndex((u) => u.id === user.value);

            const userBookings = bookings.map((booking) => {
              return [userIndex, DateTime.fromISO(booking.date).toJSDate()];
            });

            createBookingsRef.current[userIndex]?.updateBookings(userBookings);

            return userBookings;
          })
          .flat();

        useBookingsCreatingStore.getState().setBookings(newBookings);

        resolve();
      }).then(() => {
        createBookingsRequest(
          project,
          duration,
          tentative,
          startTimeWindowOpened ? parseStringTimeIntoInteger(startTime) : null,
          editorRef.current.getComment() === "<p></p>" ? "" : editorRef.current.getComment(),
          location,
          {},
          usesBudget,
          bookingTask
        );
      });
    }
  };

  const clickedOutsideOfPlanner = (e) => {
    const n = e?.target?.className ?? "";
    if (typeof n !== "string") return false;
    if (Boolean(e.target.closest(".planner__context-menu"))) return false;
    if (Boolean(e?.target?.closest(".MuiDialog-container"))) return false;

    return !Boolean(e.target.closest(".user-row"));
  };

  const translateKind = (repetition_kind) => {
    return t(`planning.${repetition_kind}`).toLowerCase();
  };

  if (!editWindowOpened) {
    return <></>;
  }

  const clickOnDeleteBooking = () => {
    if (useBookingsEditingStore.getState().bookingsEditing.length > 1) {
      setDeletionOpen(true);
    } else {
      deleteBookings();
    }
  };

  const taskOptions = () => {
    const newTaskOptions = taskArray.filter(
      (t) =>
        t.project_id === null || t.project_id === (projectsType === "default" ? selectedProject : selectedTimeOff)?.id
    );

    if (newTaskOptions.length > 0) newTaskOptions.push({ name: "No Task", id: null, project_id: null });

    return newTaskOptions;
  };

  const userOptions = () => {
    return orderedPlannerUsers.map((u) => ({ value: u.id, name: u.name }));
  };

  const usersWithEditingBookings = () => {
    const userIds = [
      ...new Set(
        bookings.map((booking) => {
          return deleteBookingsAfterSave ? null : booking.user_id;
        })
      ),
    ].filter((u) => u);

    return [...userIds, ...usersToCreateBookings.map((u) => u.value)]
      .map((userId) => {
        const user = orderedPlannerUsers.find((u) => u.id === userId);

        if (!user) {
          return null;
        }

        return { value: user.id, label: user.name };
      })
      .filter((u) => u);
  };

  const usersCanBeChanged = () => {
    const userIds = [
      ...new Set(
        bookings.map((booking) => {
          return booking.user_id;
        })
      ),
    ];

    return userIds.length === 1;
  };

  const changeCreationUsers = (users) => {
    if (!users.filter((u) => u.value === bookings[0].user_id).length > 0) {
      setDeleteBookingsAfterSave(true);
    }

    setUsersToCreateBookings(users.filter((u) => u.value !== bookings[0].user_id));
  };

  const editBookingHeader = () => {
    if (bookings.length === 1) {
      return `${t("planning.edit_booking")}: ${DateTime.fromISO(bookings[0].date).toFormat("d LLL, y")}`;
    } else {
      return t("planning.editing_n_bookings", { number: bookings.length });
    }
  };

  return (
    <ClickAwayListener
      onClickAway={(e) => {
        if (clickedOutsideOfPlanner(e)) {
          closeEditWindow();
        }
      }}
    >
      <div className="booking-window" tabIndex="0" onKeyDown={onKeyPress} id="edit-window">
        <div className="booking-window__header">
          <p>{editBookingHeader()}</p>
        </div>

        {hideTimeOffOptions ? (
          <div className="booking-window__empty_timeoffs">
            <p>
              {t("planning.no_timeoffs_1")}{" "}
              <NavLink
                to={{
                  pathname: "/settings",
                  search: "?page=time_offs",
                }}
              >
                {t("planning.time_off_link")}
              </NavLink>{" "}
              {t("planning.no_timeoffs_2")}
            </p>
          </div>
        ) : (
          <>
            <div className="booking-window__data-block__edit">
              <div className="row">
                <ProjectSelect
                  projects={projects}
                  projectsType={projectsType}
                  creationUsers={bookings.map((b) => b.user_id)}
                  selectDefaultProjects={() => changeProjectType("default")}
                  selectTimeOffProjects={() => changeProjectType("time_off")}
                />
              </div>

              {projectsType === "time_off" && <HolidayInfo />}

              {projectsType === "default" &&
                selectedProject &&
                (selectedProject.estimated > 0 || selectedProject.actual_logs_duration > 0) && (
                  <div className="row booking-window__project-estimation">
                    <ProjectBudget project={selectedProject} actuals={true} />
                  </div>
                )}

              <div className="row booking-window__data-element">
                <p className="col-md-6 booking-window__data-element-text">{t("planning.duration_hours")}</p>

                <InputNumber
                  id={"duration"}
                  step={0.5}
                  min={0.25}
                  max={24}
                  setValue={setDuration}
                  value={duration}
                  roundValue={{
                    isRoundValue: true,
                    roundStep: 4,
                  }}
                />
              </div>
              <div className="booking-window__grouped-data-element">
                <div className="row" style={{ justifyContent: "space-between" }}>
                  <TeambookIcon
                    tooltipTitle={t("planning.at_office")}
                    onClick={() => setLocation(0)}
                    name={icons.BOOKING_OFFICE}
                    placement="top"
                    style={{ opacity: location === 0 ? 1 : 0.3 }}
                    color="darkblue_new"
                  />

                  <TeambookIcon
                    id={"atClientSieBooking"}
                    alt={"on client"}
                    onClick={() => setLocation(1)}
                    name={icons.BOOKING_ON_CLIENT_SITE}
                    tooltipTitle={t("planning.on_client_site")}
                    placement="top"
                    style={{ opacity: location === 1 ? 1 : 0.3 }}
                    color="darkblue_new"
                  />

                  <TeambookIcon
                    alt={"home office"}
                    onClick={() => {
                      setLocation(2);
                    }}
                    name={icons.BOOKING_AT_HOME}
                    tooltipTitle={t("planning.home_office")}
                    placement="top"
                    style={{ opacity: location === 2 ? 1 : 0.3 }}
                    color="darkblue_new"
                  />
                </div>

                <div className="row locationPicker" style={{ padding: "0 12px" }}>
                  <Slider
                    track={false}
                    max={2}
                    value={location}
                    aria-labelledby="discrete-slider-restrict"
                    step={null}
                    onChangeCommitted={(event, val) => setLocation(val)}
                    marks={sliderMarks}
                  />
                </div>
              </div>

              {usersCanBeChanged() && (
                <div style={{ marginTop: 16 }} className="row booking-window__grouped-data-element">
                  <TeambookReactSelect
                    fieldName={t("header.users")}
                    value={usersWithEditingBookings()}
                    onChange={(users) => changeCreationUsers(users)}
                    options={userOptions()}
                    isSearchable
                    nameStyles={{ fontSize: 14, textAlign: "left" }}
                    grayPlaceholder
                    type="multiple"
                    styles={{ width: "100%" }}
                  />
                </div>
              )}

              <div style={{ marginTop: 16 }} className="row booking-window__grouped-data-element" key={bookingTask}>
                <TeambookReactSelect
                  fieldName={t("actuals.service")}
                  value={taskArray.filter((task) => task.id === bookingTask && task.id)?.[0]}
                  onChange={(task) => setBookingTask(task.value)}
                  options={taskOptions().map((t) => ({ value: t.id, name: t.name }))}
                  createOptionPosition={"first"}
                  formatCreateLabel={(option) => `${t("projects.create_task")} ${option}`}
                  onCreateOption={(option) => {
                    Api.Tasks.create({
                      project_id: (projectsType === "time_off" ? selectedTimeOff : selectedProject).id,
                      name: option,
                    }).then((res) => {
                      setTaskArray([...taskArray, res.data]);
                      setBookingTask(res.data.id);
                    });
                  }}
                  isValidNewOption={(option) =>
                    ["admin", "planner", "self_planner"].includes(role) && option.length > 2
                  }
                  isSearchable
                  nameStyles={{ fontSize: 14, textAlign: "left" }}
                  grayPlaceholder
                  placeholder={taskOptions().length === 0 ? t("planning.no_tasks") : "Select Booking Task"}
                />
              </div>

              <div style={{ marginTop: 16 }} className="row booking-window__grouped-data-element">
                <div className="checkbox">
                  <div style={{ display: "flex" }}>
                    <TeambookCheckbox
                      checked={tentative}
                      onChange={(event) => {
                        setTentative(!tentative);
                      }}
                      value={tentative}
                      color="primary"
                    />

                    <p className="booking-window__data-element-text" onClick={() => setTentative(!tentative)}>
                      {t("planning.tentative")}
                    </p>
                  </div>

                  {selectedProject?.kind === "billable" && (
                    <div style={{ display: "flex" }}>
                      <TeambookCheckbox
                        checked={!usesBudget}
                        onChange={(event) => {
                          setUsesBudget(!usesBudget);
                        }}
                        value={!usesBudget}
                        color="primary"
                      />

                      <WhiteTooltip title={t("planning.uses_budget_description")} placement="top">
                        <p className="booking-window__data-element-text" onClick={() => setUsesBudget(!usesBudget)}>
                          {t("planning.uses_budget")}
                        </p>
                      </WhiteTooltip>
                    </div>
                  )}
                </div>

                <StartTimeBlock
                  startTime={startTime}
                  setStartTime={setStartTime}
                  startTimeWindowOpened={startTimeWindowOpened}
                  setStartTimeWindowOpened={setStartTimeWindowOpened}
                  account={account}
                  bookingsDuration={duration}
                />

                {allowBookingRepetition() && (
                  <div className="row checkbox">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <TeambookCheckbox
                        checked={repetitionWindowOpened}
                        onChange={() => {
                          if (repetitionWindowOpened) {
                            setRepetitionType(null);
                          } else {
                            setRepetitionType("daily");
                          }
                          setRepetitionWindowOpened(!repetitionWindowOpened);
                        }}
                      />
                      <p
                        onClick={() => {
                          if (repetitionWindowOpened) {
                            setRepetitionType(null);
                          } else {
                            setRepetitionType("daily");
                          }
                          setRepetitionWindowOpened(!repetitionWindowOpened);
                        }}
                        className="booking-window__data-element-text"
                      >
                        {t("planning.repetition")}
                      </p>
                    </div>
                  </div>
                )}
              </div>

              {allowBookingRepetition() && repetitionWindowOpened && (
                <div className="booking-window__grouped-data-element">
                  <BookingRepetition
                    repetitionType={repetitionType}
                    setRepetitionType={setRepetitionType}
                    repetitionEvery={repetitionEvery}
                    creationBookingsArray={[[bookings[0].user_id, new Date(bookings[0].date)]]}
                    setRepetitionEvery={setRepetitionEvery}
                    repetitionEndsAfter={repetitionEndsAfter}
                    setRepetitionEndsAfter={setRepetitionEndsAfter}
                    repetitionEndsAfterNumber={repetitionEndsAfterNumber}
                    setRepetitionEndsAt={setRepetitionEndsAt}
                    repetitionEndsAt={repetitionEndsAt}
                    setRepetitionEndsAfterNumber={setRepetitionEndsAfterNumber}
                    repetitionEndsAtDate={repetitionEndsAtDate}
                    setRepetitionEndsAtDate={setRepetitionEndsAtDate}
                    repetitionIgnoreWeekends={repetitionIgnoreWeekends}
                    setRepetitionIgnoreWeekends={setRepetitionIgnoreWeekends}
                  />
                </div>
              )}

              {bookings[0].repetition &&
                (bookings[0].repetition[0].last_booking_at || bookings[0].repetition[0].ends_on) && (
                  <div className="flex">
                    <p className="booking-repetition-text">
                      {t("planning.repetition_indicator", {
                        kind: translateKind(bookings[0].repetition[0].kind),
                        range: bookings[0].repetition[0].last_booking_at || bookings[0].repetition[0].ends_on,
                      })}
                    </p>

                    {useBookingsEditingStore.getState().bookingsEditing.length === 1 && (
                      <TeambookIcon
                        name={icons.TRASH}
                        color="alternative_default"
                        tooltipTitle={t("planning.delete_repetition")}
                        onClick={deleteRepetitionClick}
                        className="booking-repetition-delete"
                      />
                    )}
                  </div>
                )}

              <div style={{ marginTop: 16 }} id="booking-notes">
                <div className="planner-editor">
                  <RichTextEditor editorHeight={100} ref={editorRef} value={bookings[0].comment} />
                </div>
              </div>

              {bookings.length === 1 && !["contractor"].includes(role) && (
                <>
                  <p className="booking-window__last-change-info">
                    Created by {nameById(bookings[0].created_by)} at{" "}
                    {displayDateTime(bookings[0].created_at, account.date_format)}
                  </p>
                  <p className="booking-window__last-change-info">
                    Updated by {nameById(bookings[0].updated_by)} at{" "}
                    {displayDateTime(bookings[0].updated_at, account.date_format)}
                  </p>
                </>
              )}
            </div>
          </>
        )}

        <div className="booking-window__control-buttons__edit">
          {!isEditingBlocked() && (
            <Button className="booking-delete-button" onClick={clickOnDeleteBooking}>
              <TeambookIcon name={icons.TRASH} color="alternative_default" tooltipTitle={t("delete")} />
            </Button>
          )}

          <TeambookOutlinedButton onClick={closeEditWindow} text={t("cancel")} style={{ width: "100%" }} />

          {!isEditingBlocked() && (
            <TeambookBlueButton
              disabled={hideTimeOffOptions}
              id={"saveEditingBooking"}
              onClick={() => {
                if (useBookingsEditingStore.getState().bookingsEditing.length > 1) {
                  setDialogWindowOpened(true);
                } else {
                  updateBookings();
                }
              }}
              text={t("save")}
              variant="contained"
              style={{ width: "100%" }}
            />
          )}
        </div>

        <DeleteDialog
          open={deletionOpen}
          setOpen={() => setDeletionOpen(false)}
          contentText={`${t("planning.deleting_multiple_bookings_1")} ${bookings.length} ${t(
            "planning.deleting_multiple_bookings_2"
          )}`}
          deleteRequest={deleteBookingsRequest}
          arrOfDeletionObjects={bookings}
        />

        <Dialog
          className="tb-default-dialog"
          fullWidth={true}
          open={dialogWindowOpened}
          onClose={() => setDialogWindowOpened(false)}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogContent style={{ margin: "20px 0px" }}>
            <p>
              {t("planning.editing_multiple_bookings_1")}
              {bookings.length}
              {t("planning.editing_multiple_bookings_2")}
            </p>
          </DialogContent>

          <DialogActions>
            <TeambookBlueButton
              text={t("yes")}
              onClick={updateBookings}
              style={{
                margin: "0 8px 0 0",
              }}
            />

            <TeambookOutlinedButton text={t("no")} onClick={() => setDialogWindowOpened(false)} />
          </DialogActions>
        </Dialog>
      </div>
    </ClickAwayListener>
  );
};

export default EditWindow;
