import React, { useContext, useEffect, useState } from "react";
import UsersProfileImage from "../../default_components/UsersProfileImage";
import { SliceStringWithDots } from "../../../lib/StringFormat";
import { useTranslation } from "react-i18next";
import { PlannerContext } from "../context/PlannerContext";
import TagInfo from "./TagInfo";
import { useMoveUserStore, useTeambookFilter, useZoomStore } from "../../../stores/planner";
import { Dropzone } from "../default_components/Dropzone";
import { useDrag, useDrop } from "react-dnd";
import { shallow } from "zustand/shallow";
import { getEmptyImage } from "react-dnd-html5-backend";
import lsKeys from "../../default_values/defaultKeys";
import { GROUPED_PLANNER_USER_WIDTH, PLANNER_USER_WIDTH } from "../../../lib/PlannerUtils";

const formatMapping = {
  0: false,
  1: false,
  2: true,
};

const UserInfo = (user, bookingFormat) => {
  let { first_name, last_name } = user;

  first_name = first_name.length > 20 ? SliceStringWithDots(first_name, 20) : first_name;
  last_name = last_name.length > 20 ? SliceStringWithDots(last_name, 20) : last_name;

  return (
    <p
      className="user-avatar__user-info"
      style={{
        margin: bookingFormat !== 2 && "0px",
      }}
    >
      {first_name} <br /> {last_name}
    </p>
  );
};

const UserAvatarCell = ({
  user,
  setUserSwapId,
  userSwapId,
  swapUsersRequest,
  bookingFormat,
  showButtons,
  height,
  style,
  groupClients,
  isDraggable,
  setEditingUser = () => {},
  groupedView = false,
  isLastUser,
}) => {
  const { t } = useTranslation();
  const [sortParam, setSortParam] = useState(localStorage.getItem("plannerUserOrder"));
  const [isHovered, setIsHovered] = useState(false);
  const [zoom] = useZoomStore((state) => [state.zoom], shallow);

  useEffect(() => {
    setSortParam(localStorage.getItem("plannerUserOrder"));
  }, [localStorage.getItem("plannerUserOrder")]);

  const role = localStorage.getItem("tb-role") || "regular";
  const canBeDraggable = !(userSwapId === null || userSwapId === user.id);

  const [, drag, dragPreview] = useDrag(
    () => ({
      type: "USER",
      item: user,
      canDrag:
        useTeambookFilter.getState().filterValues.length === 0 &&
        (sortParam === "default" || sortParam === "null") &&
        localStorage.getItem(lsKeys.LAST_PLANNER_TYPE) === "planners",
    }),
    [useTeambookFilter.getState().filterValues, role, sortParam]
  );

  useEffect(() => {
    dragPreview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  const [, drop] = useDrop(() => ({
    accept: "USER",
    drop: (item, monitor) => {
      setEndUser(user);
      swapUsersRequest(item.id);
    },
  }));

  const [setStartUser, setEndUser] = useMoveUserStore((state) => [state.setStartUser, state.setEndUser], shallow);

  const plannerType = localStorage.getItem("tbLastPlannerType");

  return (
    <>
      <Dropzone isDraggable={isDraggable} drop={drop}>
        <div
          onMouseMove={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onContextMenu={(e) => {
            e.preventDefault();
            if (["admin", "planner"].includes(role)) {
              setEditingUser(user);
            }
          }}
          onClick={(e) => {
            e.preventDefault();
            const isAdminEditBlcoked = user.role === "admin" && role === "planner";

            if (["admin", "planner"].includes(role) && !isAdminEditBlcoked) {
              setEditingUser(user);
            }
          }}
          style={{
            top: 0,
            backgroundColor: canBeDraggable ? "var(--background-2)" : "var(--background-1)",
            width: groupedView ? GROUPED_PLANNER_USER_WIDTH : PLANNER_USER_WIDTH,
            minWidth: groupedView ? GROUPED_PLANNER_USER_WIDTH : PLANNER_USER_WIDTH,
            height: height,
            left: groupClients ? "50px" : 0,
            ...style,
          }}
          className="user-avatar"
          ref={drag}
          onDragStart={(e) => {
            setStartUser(user);
          }}
        >
          <div
            className="avatar-cell-container"
            style={{
              borderBottomLeftRadius: isLastUser && 0,
            }}
          >
            <>
              <UsersProfileImage
                size={30}
                user={user}
                userImage={user.avatars["50"]}
                style={{
                  marginRight: "10px",
                }}
              />
            </>

            {UserInfo(user, bookingFormat)}
          </div>
        </div>
      </Dropzone>

      <TagInfo
        bookingFormat={+bookingFormat}
        user={user}
        isHovered={isHovered}
        setIsHovered={setIsHovered}
        showButtons={showButtons}
        height={height}
      />
    </>
  );
};

export default UserAvatarCell;
