import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Api from "../../../../../Api";
import { useTranslation } from "react-i18next";
import TeambookBlueButton from "../../../../default_components/TeambookBlueButton";
import { TeambookUsersPicker } from "../../../../default_components/TeambookDefaultPickers";
import { TeambookReactSelect } from "../../../../default_components/TeambookReactSelect";
import { TeambookIcon } from "../../../../default_images/TeambookIcon";
import { icons } from "../../../../default_images/IconsList";
import { useNotificationStore } from "../../../../../stores/notificationStore";
import { shallow } from "zustand/shallow";

const CreateHoliday = ({
  onClose,
  holidayCountries,
  holidayRegions,
  extraOptions,
  holidayUsers,
  onChangeUsersPicker,
  users,
  getHolidays,
}) => {
  const { t } = useTranslation();
  const [setInAppNotification] = useNotificationStore((state) => [state.setInAppNotification], shallow);
  const [holidayCountry, setHolidayCountry] = useState();
  const [holidayRegion, setHolidayRegion] = useState();

  const handleSubmit = () => {
    Api.Integrations.Holidays.create(
      holidayCountry,
      holidayUsers.map((u) => u.id),
      holidayRegion
    ).then(() => {
      onClose();
      setHolidayCountry("");
      getHolidays();
      setInAppNotification(t("notifications.settings.timeoff_holidays.holiday.create"));
    });
  };

  const countryRegions = () => {
    const regions = holidayRegions.find((value) => value.code === holidayCountry);

    return Object.keys(regions.regions).map((key) => ({
      name: regions.regions[key],
      value: key,
    }));
  };

  const regionPresent = () => {
    const regions = holidayRegions.find((value) => value.code === holidayCountry);
    if (regions) {
      return regions.regions;
    } else {
      return false;
    }
  };

  return (
    <Dialog
      open
      fullWidth
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth={"xs"}
      className="tb-default-dialog"
    >
      <DialogTitle id="form-dialog-title">
        <p> {t("settings.time_off.create_holiday")}</p>
        <TeambookIcon name={icons.CROSS} onClick={onClose} />
      </DialogTitle>

      <DialogContent style={{ margin: "20px 0px" }}>
        <div className="selector-container">
          <p>{t("settings.time_off.holiday_country")}</p>

          <TeambookReactSelect
            id={"selectCountry"}
            onChange={(newCountry) => {
              setHolidayCountry(newCountry.id);
              setHolidayRegion(null);
            }}
            options={holidayCountries}
            height={40}
            grayPlaceholder
            isSearchable={true}
          />
        </div>

        {regionPresent() && (
          <div className="selector-container">
            <p>{t("settings.time_off.holiday_region")}</p>

            <TeambookReactSelect
              id={"selectRegion"}
              onChange={(newRegion) => {
                setHolidayRegion(newRegion.value);
              }}
              options={countryRegions()}
              height={40}
              grayPlaceholder
            />
          </div>
        )}

        <div className="selector-container">
          <p>{t("users.tag_users")}</p>

          <TeambookUsersPicker
            styles={{
              control: (base) => ({
                ...base,
                width: "100%",
                maxWidth: "unset",
                maxHeight: 88,
                minHeight: 40,
                height: "fit-content",
              }),
              valueContainer: (base) => ({
                ...base,
                maxHeight: 84,
                height: "fit-content",
                flexWrap: "wrap !important",
                overflowY: "auto",
                overflowX: "auto !important",
                padding: "0px 4px",
              }),
            }}
            value={holidayUsers}
            id={"selectUsers"}
            onChange={onChangeUsersPicker}
            type="multiple"
            users={[...extraOptions, ...users]}
            defaultValue={holidayUsers}
          />
        </div>
      </DialogContent>

      <DialogActions>
        <TeambookBlueButton text={t("create")} id="createHolidaysButton" onClick={handleSubmit} />
      </DialogActions>
    </Dialog>
  );
};

export default CreateHoliday;
