import Api from "../../../Api";
import Header from "../../header/Header";
import "../actuals.scss";
import ActualsControlBar from "../ActualsControlBar";
import WeekRow from "../calendar/WeekRow.js";
import React, { useEffect, useRef, useState } from "react";
import LoadingComponent from "../../default_components/LoadingComponent";
import UsersColumn from "../approval_mode/UsersColumn";
import ApprovalDays from "./ApprovalDays";
import { DateTime } from "luxon";
import ActualLogRightClick from "../default_components/ActualLogRightClick";
import $ from "jquery";
import ActualsTooltip from "../default_components/ActualsTooltip";
import TooltipChanger from "../lib/TooltipChanger";
import { useTranslation } from "react-i18next";
import { useTeambookFilter } from "../../../stores/planner";
import ActualsIsBlocked from "../default_components/ActualsIsBlocked";
import { Navigate } from "react-router-dom";
import lsKeys from "../../default_values/defaultKeys";
import OnboardingComponent from "../../planner/onboarding/OnboardingComponent";
import { useProfileStore } from "../../../stores/profileStore";
import { useAccountStore } from "../../../stores/accountStore";
import { UserFeedbackBanner } from "../../default_components/UserFeedbackBanner";
import { shallow } from "zustand/shallow";
import NoBookingsFiltered from "../../planner/default_components/NoBookingsFiltered";
import { TeambookIcon } from "../../default_images/TeambookIcon.js";
import UsersProfileImage from "../../default_components/UsersProfileImage.js";

const ApprovalPage = () => {
  const { t } = useTranslation();
  const role = localStorage.getItem(lsKeys.ROLE);

  const splitOptions = [
    { name: t("actuals.approve_by_user"), value: "by_user" },
    { name: t("actuals.approve_by_project"), value: "by_project" },
  ];

  const { filterValues, setFilterValue } = useTeambookFilter();

  const [projects, setProjects] = useState();
  const [users, setUsers] = useState();
  const [selectedUser, setSelectedUser] = useState(null);
  const [sortMode, setSortMode] = useState("a");
  const [date, setDate] = useState(DateTime.now().startOf("month"));
  const [beginningDate, setBeginningDate] = useState();
  const [allUserLogs, setAllUserLogs] = useState([]);
  const [teams, setTeams] = useState([]);
  const [tags, setTags] = useState();
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [shownUsers, setShownUsers] = useState([]);
  const [occupations, setOccupations] = useState([]);
  const [activities, setActivities] = useState([]);
  const [splitBy, setSplitBy] = useState(splitOptions[0].value);
  const [shownProjects, setShownProjects] = useState([]);
  const [emtpyFiltered, setEmptyFiltered] = useState(false);
  const [actualsAvailable, setActualsAvailable] = useState(true);
  const [onboardingOpened, setOnboardingOpened] = useState(
    localStorage.getItem(lsKeys.ACTUALS_ONBOARDING_OPENED) === "true"
  );

  const [profile] = useProfileStore((state) => [state.profile], shallow);
  const [account] = useAccountStore((state) => [state.account], shallow);

  const actualLogRightClick = useRef(null);
  const actualLogRefTooltip = useRef(null);

  const isLoaded = !!account && !!projects && !!profile && !!users && !!selectedUser && !!projects && !!tags;

  useEffect(() => {
    GetProjects();
    GetUsers();
    GetTeams();
    GetActivities();
    GetOccupations();
    GetTags();
  }, []);

  useEffect(() => {
    if (account) {
      setActualsAvailable(account?.actuals_is_available);
    }
  }, [account]);

  useEffect(() => {
    if (projects && users) {
      if (splitBy === "by_user") {
        setSelectedUser(users[0]);
      } else {
        setSelectedUser(projects[0]);
      }
    }
  }, [splitBy, users, projects]);

  useEffect(() => {
    localStorage.removeItem(lsKeys.LAST_ACTUALS_FILTERS);
  }, [splitBy]);

  useEffect(() => {
    updateActuals();
  }, [users, beginningDate]);

  useEffect(() => {
    setBeginningDate(date.startOf("week"));
  }, [date]);

  useEffect(() => {
    setBeginningDate(date.startOf("week").minus({ day: account?.first_week_day === "Sunday" ? 1 : 0 }));
  }, [account]);

  useEffect(() => {
    setEmptyFiltered(false);
    if (users && splitBy === "by_user") {
      let userIds = [];

      filterValues.forEach((filterVal) => {
        if (filterVal.type === t("planning.filter_teams")) {
          userIds.push(
            ...(teams
              .filter((team) => team.id === filterVal.id)[0]
              ?.team_users.map((u) => u.id)
              ?.flat() || [])
          );
        } else if (filterVal.type === t("planning.filter_users")) {
          userIds.push(filterVal.id);
        } else if (filterVal.type === t("planning.filter_tags")) {
          userIds.push(...filterVal.tag_users);
        }
      });

      if (filterValues.length > 0) {
        let usersToDisplay = Array.from(new Set(userIds))
          .map((id) => users.find((user) => user.id === id))
          .filter((user) => user);

        setShownUsers(usersToDisplay);

        if (usersToDisplay.length === 0) {
          setEmptyFiltered(true);
          return;
        }

        if (!usersToDisplay.find((user) => user?.id === selectedUser?.id)) {
          setSelectedUser(usersToDisplay[0]);
        }
      }

      if (filterValues.length === 0) {
        setShownUsers(users.filter((u) => u.active));
        if (profile) {
          setSelectedUser(profile);
        }
      }
    }

    if (projects && splitBy === "by_project") {
      let projectIds = [];
      filterValues.forEach((filterVal) => {
        if (filterVal.type === t("planning.filter_projects")) {
          projectIds.push(filterVal.id);
        }
      });

      if (filterValues.length > 0) {
        let projectsToDisplay = Array.from(new Set(projectIds)).map((id) => projects.find((proj) => proj.id === id));

        setShownProjects(projectsToDisplay);

        if (projectsToDisplay.length === 0) {
          setEmptyFiltered(true);
          return;
        }

        if (!projectsToDisplay.find((proj) => proj.id === selectedUser.id)) {
          setSelectedUser(projectsToDisplay[0]);
        }
      }

      if (filterValues.length === 0) {
        setShownProjects(projects);
        setSelectedUser(profile);
      }
    }
  }, [selectedTeams, users, projects, filterValues, profile]);

  const GetUsers = () =>
    Api.Users.all().then((response) => {
      let activeUsers = response.data.filter(({ active }) => !!active);
      setUsers(activeUsers);
      setShownUsers(activeUsers);
      updateActuals();
    });

  const GetTeams = () => {
    Api.Teams.all().then((res) => {
      setTeams(res.data);
    });
  };

  const GetActivities = () => {
    Api.ActualLogs.get_activities().then((response) => {
      setActivities(response.data);
    });
  };

  const GetTags = () => {
    Api.Tags.all().then((response) => {
      setTags(response.data);
    });
  };

  const GetOccupations = () => {
    Api.ActualLogs.get_occupations().then((response) => {
      setOccupations(response.data);
    });
  };

  const GetProjects = () =>
    Api.Projects.all().then((response) => {
      setProjects(response.data);
      setShownProjects(response.data.filter((pr) => pr.active));
    });

  const getUserIds = () => {
    return users.map(({ id }) => id);
  };

  const updateActuals = () => {
    if (users) {
      Api.ActualLogs.get({
        user_ids: [...getUserIds()],
        start_date: beginningDate.toISODate(),
        end_date: date.plus({ month: 1 }).endOf("week").toISODate(),
      }).then((res) => {
        setAllUserLogs(res.data.sort((a, b) => (a.created_at > b.created_at ? 1 : -1)));
      });
    }
  };

  const createOccupation = (occupation, setSelectedOccupation) => {
    Api.ActualLogs.create_occupation({ name: occupation }).then((response) => {
      setOccupations([...occupations, response.data]);
      // setSelectedOccupation(response.data);
    });
  };

  const createActivity = (activity, setSelectedActivity) => {
    Api.ActualLogs.create_activity({ name: activity }).then((response) => {
      setActivities([...activities, response.data]);
      // setSelectedActivity(response.data);
    });
  };

  const changeTooltip = (newTooltip) => {
    TooltipChanger({
      ...newTooltip,
      positionX: newTooltip.positionX + 7,
      positionY: newTooltip.positionY - 108,
    });
  };

  const closeManageWindow = () => {
    GetActivities();
    GetOccupations();
    updateActuals();
  };

  const changeContextMenu = (newMenu) => {
    let scrollGrid = $("#actuals-scroll-grid");

    actualLogRightClick.current.setOpen(newMenu.open);
    actualLogRightClick.current.setPos({
      x: newMenu.pos.x - 445,
      y: newMenu.pos.y - 145 + scrollGrid.scrollTop(),
    });
    actualLogRightClick.current.setLog(newMenu.log);
  };

  const getLogsToDisplay = () => {
    if (splitBy === "by_user") {
      return allUserLogs.filter((log) => log.user_id === selectedUser.id);
    } else {
      return allUserLogs.filter((log) => log.project_id === selectedUser.id);
    }
  };

  if (!["admin", "planner"].includes(role)) {
    return <Navigate to="/actuals/time_logging" />;
  }

  return (
    <>
      <Header page={"actuals"} setOnboardingOpened={setOnboardingOpened} />

      {!isLoaded ? (
        <LoadingComponent />
      ) : actualsAvailable ? (
        <>
          <ActualsControlBar
            selectedUser={selectedUser}
            setDate={setDate}
            date={date}
            setSortMode={setSortMode}
            sortMode={sortMode}
            approvalMode={true}
            teams={teams}
            selectedTeams={selectedTeams}
            users={users}
            updateActuals={updateActuals}
            closeManageWindow={closeManageWindow}
            projects={projects}
            tags={tags}
            splitBy={splitBy}
          />

          {emtpyFiltered ? (
            <NoBookingsFiltered />
          ) : (
            <div className="actuals-grid">
              <UsersColumn
                setSplitBy={setSplitBy}
                splitBy={splitBy}
                sortMode={sortMode}
                setSortMode={setSortMode}
                users={shownUsers}
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
                allUserLogs={allUserLogs}
                date={date}
                splitOptions={splitOptions}
                projects={shownProjects}
              />

              <div
                style={{
                  position: "absolute",
                  left: 376,
                  marginRight: 30,
                  width: "calc(100% - 396px)",
                }}
              >
                <div className="approval-page__selected">
                  <p className="approval-page__approbation">{t("actuals.approbation")}:</p>

                  <div className="approval-page__selected-container">
                    {selectedUser?.avatars ? (
                      <UsersProfileImage
                        className="approval-page__current-user"
                        style={{ width: 30, height: 30 }}
                        user={selectedUser}
                      />
                    ) : (
                      <div className="approval-page__current-project" style={{ background: selectedUser.color }} />
                    )}

                    <p>{selectedUser.name}</p>
                  </div>

                  <div className="approval-page__approval-data">
                    <div className="approval-page__logged-hours">
                      <p>
                        {getLogsToDisplay()
                          .filter((log) => DateTime.fromISO(log.date).startOf("month").ts === date.startOf("month").ts)
                          .reduce((prev, cur) => prev + cur.duration / 60, 0)}{" "}
                        h
                      </p>

                      <p style={{ fontSize: 12, lineHeight: "12px" }}>{t("actuals.reported")}</p>
                    </div>

                    <div className="approval-page__approved-hours">
                      <p>
                        {getLogsToDisplay()
                          .filter(
                            (log) =>
                              log.approved &&
                              DateTime.fromISO(log.date).startOf("month").ts === date.startOf("month").ts
                          )
                          .reduce((prev, cur) => {
                            if (cur.approved) {
                              return prev + cur.duration / 60;
                            }
                          }, 0)}{" "}
                        h
                      </p>

                      <p style={{ fontSize: 12, lineHeight: "12px" }}>{t("actuals.approved")}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                id="actuals-scroll-grid"
                style={{
                  position: "absolute",
                  left: 376,
                  width: "calc(100% - 383px)",
                  overflowY: "scroll",
                  bottom: 0,
                  top: 0,
                }}
              >
                <WeekRow type={"approval"} />

                {occupations && activities && (
                  <ApprovalDays
                    changeContextMenu={changeContextMenu}
                    changeTooltip={changeTooltip}
                    projects={projects}
                    date={date}
                    user={selectedUser}
                    updateActuals={updateActuals}
                    actualLogs={getLogsToDisplay()}
                    beginningDate={beginningDate}
                    activities={activities}
                    occupations={occupations}
                    createOccupation={createOccupation}
                    createActivity={createActivity}
                    splitBy={splitBy}
                    users={users}
                  />
                )}

                <ActualLogRightClick
                  changeTooltip={changeTooltip}
                  updateActuals={updateActuals}
                  page={"approval"}
                  ref={actualLogRightClick}
                />
              </div>

              <ActualsTooltip ref={actualLogRefTooltip} />
            </div>
          )}
        </>
      ) : (
        <>
          <ActualsIsBlocked />
        </>
      )}

      <OnboardingComponent
        page="actuals"
        onboardingOpened={onboardingOpened}
        setOnboardingOpened={setOnboardingOpened}
        role={localStorage.getItem(lsKeys.ROLE || "regular")}
      />

      {profile && <UserFeedbackBanner />}
    </>
  );
};

export default ApprovalPage;
