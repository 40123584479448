export const headerColumns = (customProjectFields, t) => [
  {
    fieldText: t("projects.short_name"),
    className: "projects-page__project-list-header_field bookings_duration mobile_hidden column-width-2",
    columnField: "code",
    name: "short_name",
  },
  {
    fieldText: t("projects.client"),
    className: "projects-page__project-list-header_field client_name column-width-1",
    columnField: "client_name",
    name: "client",
  },
  {
    fieldText: t("projects.type"),
    className: "projects-page__project-list-header_field kind column-width-1",
    columnField: "kind",
    name: "billable",
  },
  {
    fieldText: t("projects.start_date"),
    className: "projects-page__project-list-header_field start_date mobile_hidden column-width-1",
    columnField: "start_date",
    name: "start_date",
  },
  {
    fieldText: t("projects.end_date"),
    className: "projects-page__project-list-header_field end_date mobile_hidden column-width-1",
    columnField: "end_date",
    name: "end_date",
  },
  {
    fieldText: t("projects.manager"),
    className: "projects-page__project-list-header_field kind column-width-1",
    columnField: "manager",
    name: "manager",
  },
  {
    fieldText: t("projects.status"),
    className: "projects-page__project-list-header_field kind column-width-1",
    columnField: "status",
    name: "status",
  },
  {
    fieldText: t("projects.business_unit"),
    className: "projects-page__project-list-header_field kind column-width-1",
    columnField: "business_unit",
    name: "business_unit",
  },
  {
    fieldText: t("projects.planned"),
    className: "projects-page__project-list-header_field bookings_duration mobile_hidden column-width-2",
    columnField: "bookings_duration",
    style: { overflow: "unset" },
    name: "planned_hours",
  },
  {
    fieldText: t("projects.estimated"),
    className:
      "projects-page__project-list-header_field bookings_duration mobile_hidden bookings_estimated column-width-2",
    columnField: "estimated",
    style: { overflow: "unset" },
    name: "planned_hours",
  },
  {
    fieldText: t("projects.actual_hours"),
    className: "projects-page__project-list-header_field kind column-width-2",
    columnField: "actual_logs_duration",
    name: "logged_hours",
  },
  {
    fieldText: t("projects.estimated"),
    className:
      "projects-page__project-list-header_field bookings_duration mobile_hidden bookings_estimated column-width-2",
    columnField: "estimated_logs",
    style: { overflow: "unset" },
    name: "logged_hours",
  },
  {
    fieldText: t("projects.user_capacity"),
    className: "projects-page__project-list-header_field kind column-width-2",
    columnField: "user_capacity_duration",
    name: "capacity_days",
  },
  {
    fieldText: t("projects.estimated_man_days"),
    className:
      "projects-page__project-list-header_field bookings_duration mobile_hidden bookings_estimated column-width-2",
    columnField: "estimated_capacity",
    style: { overflow: "unset" },
    name: "capacity_days",
  },
  ...customProjectFields
    .filter((field) => field)
    .map((field) => {
      return {
        fieldText: field,
        className: "projects-page__project-list-header_field client_name column-width-3",
        columnField: field,
        style: { overflow: "unset" },
        name: field,
        custom: true,
      };
    }),
];

export const availableColumns = [
  "short_name",
  "client",
  "billable",
  "start_date",
  "end_date",
  "manager",
  "status",
  "business_unit",
  "planned_hours",
  "logged_hours",
  "capacity_days",
];

export const defaultData = {
  short_name: true,
  client: true,
  billable: true,
  start_date: true,
  end_date: true,
  planned_hours: true,
  logged_hours: true,
  manager: false,
  status: false,
  business_unit: false,
  capacity_days: false,
};
