import React, { useState, useEffect, useContext } from "react";
import { GetWeekMonday, OffsetDays } from "../../../lib/DateUtils";
import moment from "moment";
import { useTranslation } from "react-i18next";
import AddUserPlusButton from "../users_planner/components/AddUserPlusButton";
import "moment/locale/de";
import "moment/locale/fr";
import "moment/locale/es";
import revertIcon from "../../default_images/revert_blue.svg";
import SettingsDialog from "../modals/SettingsDialog";
import PlannerSelect from "./PlannerSelect";
import { WhiteTooltip } from "../../default_components/Tooltips";
import Api from "../../../Api";
import {
  useBookingsCreatingStore,
  useBookingsEditingStore,
  useBookingsStore,
  useCopyBookingsStore,
  useDateStore,
  useTeambookFilter,
  useZoomStore,
} from "../../../stores/planner";
import TeambookFilterComponent from "../../default_components/teambook_filter/TeambookFilterComponent";
import lsKeys from "../../default_values/defaultKeys";
import { PlannerContext } from "../context/PlannerContext";
import { DateTime } from "luxon";
import { shallow } from "zustand/shallow";
import TeambookDatePicker from "../../default_components/teambook_date_selector/DatePicker";
import { icons } from "../../default_images/IconsList";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { TeambookTeamsPicker } from "../../default_components/TeambookDefaultPickers";
import { useProfileStore } from "../../../stores/profileStore";
import { useAccountStore } from "../../../stores/accountStore";
import AddProjectPlusButton from "../grouped_projects_planner/components/AddProjectPlusButton";
import { useNotificationStore } from "../../../stores/notificationStore";
import { copyBookingAvailable } from "../lib/plannerFunctions";
import { TodayButton } from "../../default_components/TodayButton";

const getAllIndexes = (arr, userId) => {
  let indexes = [];
  arr.forEach((user, i) => {
    if (user.id === userId) {
      indexes.push(i);
    }
  });

  return indexes;
};

const PlannerControlBar = ({
  changeDate,
  teams,
  teamId,
  plannerView,
  openAddUserWindow,
  openAddProjectWindow,
  role,
  changeTooltipDisabling,
  bookingFormat,
  setBookingFormat,
  disableTooltip,
  weekendsHidden,
  changeWeekendsHidden,
  plannerDispatcher,
  usersRef,
  closeEditWindow,
  createBookingsRef,
  groupedProjectUsers,
  hideOtherUsers,
  setHideOtherUsers,
  groupClients,
  setGroupClients,
  activeProjects,
  businessUnits = [],
  filterPage,
  departments = [],
}) => {
  const { t } = useTranslation();

  const [setBookings] = useBookingsStore((state) => [state.setBookings], shallow);
  const [setInAppNotification] = useNotificationStore((state) => [state.setInAppNotification], shallow);
  const [bookingsEditing, setBookingsEditing, setEditWindowOpened, editWindowOpened] = useBookingsEditingStore(
    (state) => [state.bookingsEditing, state.setBookings, state.setEditWindowOpened, state.editWindowOpened],
    shallow
  );
  const { filterValues, setFilterValue } = useTeambookFilter();
  const [setCreationBookingDuration] = useBookingsCreatingStore((state) => [state.setDuration], shallow);
  const [setCopyModeActive, setCopyBookingIds, copyModeActive] = useCopyBookingsStore(
    (state) => [state.setCopyModeActive, state.setCopyBookingIds, state.copyModeActive],
    shallow
  );
  const { filteredUsers, projects, clients, tags } = useContext(PlannerContext);
  const [zoom] = useZoomStore((state) => [state.zoom], shallow);
  const [account] = useAccountStore((state) => [state.account], shallow);
  const [profile] = useProfileStore((state) => [state.profile], shallow);

  const [clientWidth, setDocumentWidth] = useState(document.body.clientWidth);
  const [version, setVersion] = useState(
    clientWidth < 640 ? "mobile" : (clientWidth >= 640 && clientWidth) < 1100 ? "tablet" : "desktop"
  );
  const [settingsOpened, setSettingsOpened] = useState(false);

  const [date, setDate] = useDateStore((state) => [state.date, state.setDate], shallow);

  window.addEventListener("resize", () => setDocumentWidth(window.innerWidth));

  const startCopyProcess = () => {
    setCopyModeActive(true);
    setCopyBookingIds(bookingsEditing.map((b) => b.id));

    if (editWindowOpened) {
      setEditWindowOpened(false);
    }

    setBookingsEditing([]);
  };

  const cancelCopy = () => {
    setCopyModeActive(false);
    setCopyBookingIds([]);
    closeEditWindow();
  };

  const selectTeam = (value) => {
    setFilterValue([]);

    localStorage.setItem(lsKeys.LAST_PLANNER_FILTERS, JSON.stringify([]));
    localStorage.setItem(lsKeys.LAST_TEAMID_KEY, value.id);

    window.location.replace(`/${localStorage.getItem(lsKeys.LAST_PLANNER_TYPE)}`);
  };

  if (account) {
    moment.updateLocale("en", {
      week: {
        dow: account.first_week_day === "Monday" ? 1 : 0,
      },
    });
  }

  const showActionButtons = () => {
    return ["admin", "planner", "self_planner"].includes(role);
  };

  let plannerUsers = groupedProjectUsers || filteredUsers;

  const revertAction = () => {
    Api.Bookings.revert_action()
      .then((response) => {
        let allBookings = [...useBookingsStore.getState().bookings];

        response.data?.bookings.forEach((revertedBooking) => {
          if (allBookings.some((booking) => booking.id === revertedBooking.id)) {
            if (response.data?.action === "delete") {
              allBookings = allBookings.filter((b) => b.id !== revertedBooking.id);
            } else {
              allBookings[allBookings.findIndex((booking) => booking.id === revertedBooking.id)] = revertedBooking;
            }
          } else {
            allBookings = [...allBookings, revertedBooking];
          }
        });

        setBookings(allBookings);
        setCreationBookingDuration(account?.default_booking_duration / 60);
        createBookingsRef?.current?.forEach((ref) => {
          ref.updateDuration(0);
        });

        plannerDispatcher.afterCreationBookingAssignment(
          plannerUsers.map((user) => getAllIndexes(plannerUsers, user.id)).flat()
        );

        setInAppNotification(t("notifications.planning.bookings_reverted"));
      })
      .catch();
  };

  let map = {};
  onkeydown = onkeyup = (e) => {
    map[e.keyCode] = e.type === "keydown";
    if (map[17] && map[90]) {
      revertAction();
    }
  };

  const step = () => {
    switch (zoom) {
      case 1:
        return 1;
      case 14:
        return 21;
      case 28:
        return 28;
      default:
        return 30;
    }
  };

  const getDateFormat = () => {
    if (zoom === 1) {
      return "d LLLL";
    } else {
      return version !== "mobile" ? "MMM yyyy" : "MMMM YYYY";
    }
  };

  const getFilterProjects = () => {
    if (localStorage.getItem(lsKeys.LAST_PLANNER_TYPE === "planners")) {
      return (
        activeProjects.filter((project) =>
          useBookingsStore.getState().bookings.some((b) => b.project_id === project.id)
        ) || []
      );
    } else {
      return (
        activeProjects
          .filter((project) => useBookingsStore.getState().bookings.some((b) => b.project_id === project.id))
          .filter(({ kind }) => kind !== "time_off") || []
      );
    }
  };

  const getFilterClients = () => {
    const clientsWithBookings = [];

    getFilterProjects().forEach((pr) => {
      if (pr.client_id && !clientsWithBookings.includes(pr.client_id)) {
        clientsWithBookings.push(pr.client_id);
      }
    });

    return clients.filter((client) => clientsWithBookings.includes(client.id));
  };

  return (
    <div className="planner-page__planner-control">
      <div className="planner-page__control-bar-first-line">
        <div className="planner-page__date-container">
          <TodayButton page="planner" setDate={changeDate} />

          <TeambookDatePicker
            from={DateTime.fromJSDate(
              zoom === 1
                ? date
                : OffsetDays(date, localStorage.getItem(lsKeys.LAST_PLANNER_TYPE) === "grouped_planners" ? 6 : 20)
            )}
            formatStart={getDateFormat()}
            height={40}
            onDayClick={(date) => {
              if (zoom === 1) {
                changeDate(date.toJSDate());
              } else {
                changeDate(GetWeekMonday(date));
              }
            }}
            onArrowClicks={{
              left: () => {
                if (zoom === 1) {
                  changeDate(OffsetDays(date, -step()));
                } else {
                  changeDate(GetWeekMonday(OffsetDays(date, -step())));
                }
              },
              right: () => {
                if (zoom === 1) {
                  changeDate(OffsetDays(date, step()));
                } else {
                  changeDate(GetWeekMonday(OffsetDays(date, step())));
                }
              },
            }}
            highlight="today"
            range={1}
            setFrom={setDate}
            closeOnSelect={true}
            type="default"
            arrowColor="darkblue_new"
          />
        </div>

        <div
          className="planner-control__button desktop_hidden"
          onClick={() => {
            revertAction();
          }}
        >
          <img className="planner-control-panel__setting-icon" alt={"gear"} src={revertIcon} />
        </div>

        <div
          className="planner-control__button desktop_hidden"
          onClick={() => {
            if (bookingsEditing.length === 1) {
              startCopyProcess();
            } else {
              alert("Please select a booking to copy.");
            }
          }}
        >
          <TeambookIcon
            id={"copy"}
            className="planner-control-panel__setting-icon"
            alt={"gear"}
            name={icons.COPY}
            color="alternative_default"
          />
        </div>

        <div className="planner-control__filter-text-field mobile_hidden">
          <TeambookFilterComponent
            users={teams.find((team) => team.id === teamId)?.team_users}
            clients={getFilterClients()}
            projects={getFilterProjects()}
            tags={tags}
            businessUnits={businessUnits}
            filterPage={filterPage}
            departments={departments}
          />
        </div>

        {teams.length > 1 && (
          <TeambookTeamsPicker
            classNamePrefix={"tb-react-planner-selector"}
            teams={teams}
            value={teams.find((team) => team.id === teamId)}
            onChange={selectTeam}
            minWidth={128}
            maxWidth={304}
            height={40}
            isSearchable={false}
          />
        )}

        <div className="control-bar-second-line__whitespace right mobile_hidden" style={{ right: 0 }}>
          {showActionButtons() && (
            <div className="planner-control-bar__action-buttons">
              {["admin", "planner"].includes(role) && plannerView !== t("planning.select_by_grouped") && (
                <div className="control-bar-second-line__whitespace mobile_hidden left">
                  <AddUserPlusButton
                    className="mobile_hidden"
                    handleClickOpen={openAddUserWindow}
                    disabled={filterValues.length > 0}
                  />
                </div>
              )}

              {["admin", "planner"].includes(role) && plannerView === t("planning.select_by_grouped") && (
                <div className="control-bar-second-line__whitespace mobile_hidden left">
                  <AddProjectPlusButton
                    className="mobile_hidden"
                    handleClickOpen={openAddProjectWindow}
                    disabled={filterValues.length > 0}
                  />
                </div>
              )}

              <WhiteTooltip title={t("planning.control_panel.revert_action")}>
                <div
                  className={`planner-control__button mobile_hidden`}
                  onClick={() => {
                    revertAction();
                  }}
                >
                  <TeambookIcon
                    id="revertAction"
                    className="planner-control-panel__setting-icon revert"
                    alt={"revert"}
                    name={icons.REVERT}
                    color="darkblue_new"
                  />
                </div>
              </WhiteTooltip>

              <WhiteTooltip title={t("planning.control_panel.copy_booking")}>
                <div
                  className={`planner-control__button mobile_hidden ${
                    (role === "self_planner" && bookingsEditing.every((b) => b.user_id !== profile.id)) ||
                    copyBookingAvailable(bookingsEditing)
                      ? ""
                      : "disabled"
                  }`}
                  onClick={() => {
                    if (bookingsEditing.length > 0) {
                      startCopyProcess();
                    } else {
                      alert("Please select a booking to copy.");
                    }
                  }}
                >
                  <TeambookIcon
                    id={"copy"}
                    className="planner-control-panel__setting-icon"
                    alt={"copy"}
                    name={icons.COPY}
                    style={{ width: 22, height: 22 }}
                    color="darkblue_new"
                  />
                </div>
              </WhiteTooltip>

              {!["contractor"].includes(role) && (
                <WhiteTooltip title={t("print")}>
                  <a
                    href={`/planner_to_pdf/${teamId}/${DateTime.now().startOf("week").toISODate()}`}
                    target="_blank"
                    rel="noreferrer"
                    className={`planner-control__button mobile_hidden ${
                      role === "self_planner" && bookingsEditing.some((b) => b.user_id !== profile.id)
                        ? "disabled"
                        : " "
                    }`}
                  >
                    <TeambookIcon
                      id={"download"}
                      className="planner-control-panel__setting-icon"
                      alt={"download"}
                      name={icons.PRINT}
                      style={{ width: 22, height: 22 }}
                      color="darkblue_new"
                    />
                  </a>
                </WhiteTooltip>
              )}
            </div>
          )}

          <WhiteTooltip title={t("planning.control_panel.visual_settings")}>
            <div
              className="planner-control__button mobile_hidden"
              onClick={() => {
                setSettingsOpened(true);
              }}
            >
              <TeambookIcon
                id={"settings"}
                className="planner-control-panel__setting-icon"
                alt={"gear"}
                name={icons.EYE_OPENED}
                style={{ width: 22, height: 22 }}
                color="darkblue_new"
              />
            </div>
          </WhiteTooltip>

          <PlannerSelect className="mobile_hidden" pageName={plannerView} />
        </div>

        <SettingsDialog
          changeTooltipDisabling={changeTooltipDisabling}
          disableTooltip={disableTooltip}
          weekendsHidden={weekendsHidden}
          changeWeekendsHidden={changeWeekendsHidden}
          closeWindow={setSettingsOpened}
          open={settingsOpened}
          bookingFormat={bookingFormat}
          setBookingFormat={setBookingFormat}
          teamId={teamId}
          hideOtherUsers={hideOtherUsers}
          setHideOtherUsers={setHideOtherUsers}
          groupClients={groupClients}
          setGroupClients={setGroupClients}
          changeDate={changeDate}
        />
      </div>

      <TeambookFilterComponent
        users={teams.find((team) => team.id === teamId)?.team_users}
        clients={clients}
        projects={projects || []}
        tags={tags}
        className="desktop_hidden"
        businessUnits={businessUnits}
        filterPage={filterPage}
        departments={departments}
      />

      {copyModeActive && (
        <div className="planner-control-panel__copy-information">
          <p className="info-text">{t("planning.control_panel.info_1")}</p>
          <p className="cancel-button" onClick={cancelCopy}>
            {t("finish")}
          </p>
        </div>
      )}
    </div>
  );
};

export default PlannerControlBar;
