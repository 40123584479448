import React, { useEffect, useState } from "react";
import Api from "../../../../Api";
import { useTranslation } from "react-i18next";
import { IconWrapper, phoneRegex } from "./ClientsDialog";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";
import { shallow } from "zustand/shallow";
import { useNotificationStore } from "../../../../stores/notificationStore";

const EditClientForm = ({ client, editClient, getAllClients, getAllProjects }) => {
  const { t } = useTranslation();
  const [setInAppNotification] = useNotificationStore((state) => [state.setInAppNotification], shallow);
  const [name, setName] = useState(client.name || "");
  const [email, setEmail] = useState(client.email || "");
  const [phone_number, setPhone_number] = useState(client.phone_number || "");
  const [errorMessage, setErrorMessage] = useState(null);
  const [wrongComponent, setWrongComponent] = useState(null);

  useEffect(() => {
    setErrorMessage(null);
    setWrongComponent(null);
  }, [name, email, phone_number]);

  const updateClient = () => {
    Api.Clients.update(client.id, {
      name,
      email,
      phone_number,
    })
      .then(() => {
        getAllClients();
        getAllProjects();
        editClient(null);
        setErrorMessage(null);
        setWrongComponent(null);
        setInAppNotification(t("notifications.projects.clients.edit"));
      })
      .catch((error) => {
        setErrorMessage(error.response.data.error.message);
        setWrongComponent(error.response.data.error.field);
      });
  };

  const phoneOnChange = (event) => {
    const text = event.target.value;

    if (text.length > 0) {
      phoneRegex.test(text) && setPhone_number(text);
    } else {
      setPhone_number(text);
    }
  };

  const closeEditClient = () => {
    editClient(null);
  };

  return (
    <>
      <div className={`clients-modal__row wrong-${wrongComponent}`}>
        <input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Client Name" />
        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
        <input type="text" value={phone_number} onChange={phoneOnChange} placeholder="Phone" />

        <div className="clients-modal__icons-container">
          <IconWrapper t={t} onClick={updateClient} toolTip="save" icon={icons.CHECK} disable={errorMessage} />
          <IconWrapper t={t} onClick={closeEditClient} toolTip="cancel" icon={icons.CROSS} />
        </div>
      </div>

      {errorMessage && (
        <div className={`clients-modal__error-message wrong-${wrongComponent}`}>
          <TeambookIcon
            name={icons.WARNING}
            color="red"
            style={{
              marginTop: errorMessage.split(" ").length > 6 ? "8px" : "0px",
            }}
          />
          <p>{errorMessage}</p>
        </div>
      )}
    </>
  );
};

export default EditClientForm;
