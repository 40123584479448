import UsersProfileImage from "../../default_components/UsersProfileImage";
import { approvedThisMonth, loggedThisMonth } from "../lib/calculateLogData";

const User = ({ user, selectedUser, setSelectedUser, allUserLogs, date }) => {
  const WorkingTimeInMonth = (user, date) => {
    if (user.custom_working_hours) return user.custom_working_hours;

    const firstDateOfMonth = date.startOf("month");
    let workingHoursInMonth = 0;

    for (let i = 0; i < date.endOf("month").day; i++) {
      const dateSchedule = user.schedule[firstDateOfMonth.plus({ day: i }).weekday % 7];

      workingHoursInMonth += dateSchedule[0] + dateSchedule[2];
    }

    return workingHoursInMonth / 60;
  };

  return (
    <div
      key={user.id}
      className={`column-user__form ${user.id === selectedUser.id ? " column-user__form_selected" : ""}`}
      onClick={() => setSelectedUser(user)}
    >
      <div className="column-user__avatar-block">
        <UsersProfileImage size={30} user={user} userImage={user.avatars["50"]} header={true} />
      </div>
      <div className="column-user__user-info">
        <p>
          {console.log(user.first_name)}
          {user.first_name} <br />
          {user.last_name}
        </p>
      </div>
      <div className="column-user__user-approval-hours">
        <p title="Total Working Hours">{WorkingTimeInMonth(user, date)}h /&nbsp;</p>

        <p title="Total Logged">{loggedThisMonth(allUserLogs, date) || 0}h /&nbsp;</p>

        <p title={"Total Approved"}>{approvedThisMonth(allUserLogs, date) || 0}h</p>
      </div>
    </div>
  );
};

export default User;
