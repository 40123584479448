import { DialogTitle } from "@mui/material";
import { WhiteTooltip } from "../Tooltips";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";
import DialogContent from "@mui/material/DialogContent";
import TeambookTextForm from "../TeambookTextForm";
import FormErrorMessage from "../FormErrorMessage";
import Dialog from "@mui/material/Dialog";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import LoadingComponent from "../LoadingComponent";
import entityMapping from "./entityMapping";
import DeleteEntityDialog from "./DeleteEntityDialog";
import EntityTooltip from "./EntityTooltip";

const TeambookEntityManagementDialog = ({
  entityType,
  dialogOpened,
  setDialogOpened,
  setMasterEntities,
  masterEntities,
}) => {
  const { t } = useTranslation();

  const entitySettings = entityMapping[entityType];
  const [creationOpened, setCreationOpened] = useState(false);
  const [creationFields, setCreationFields] = useState(entitySettings.defaultCreationValues());
  const [editingFields, setEditingFields] = useState({});
  const [entityToDelete, setEntityToDelete] = useState();
  const [entityToEdit, setEntityToEdit] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [hoverableData, setHoverableData] = useState();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [sortField, setSortField] = useState();
  const [sortDirection, setSortDirection] = useState("asc");

  const entityTooltipRef = useRef(null);

  useEffect(() => {
    entitySettings.getEntities(setMasterEntities);
  }, []);

  useEffect(() => {
    setCreationFields(entitySettings.defaultCreationValues());
    setEditingFields({});
  }, [creationOpened]);

  useEffect(() => {
    sortRecords(masterEntities, sortField, sortDirection);
  }, [sortField, sortDirection]);

  const createEntity = () => {
    closeErrorMessage();

    const validationOk = validateInput(creationFields);

    if (validationOk) {
      entitySettings
        .create(creationFields)
        .then((res) => {
          entitySettings.getEntities(setMasterEntities);
          setCreationOpened(false);
        })
        .catch((error) => {
          setErrorMessage(error.response.data.error);
        });
    }
  };

  const updateEntity = () => {
    closeErrorMessage();

    const validationOk = validateInput(editingFields);

    if (validationOk) {
      entitySettings
        .update(entityToEdit, editingFields)
        .then(() => {
          entitySettings.getEntities(setMasterEntities);
          setEntityToEdit(null);
        })
        .catch((error) => {
          setErrorMessage(error.response.data.error);
        });
    }
  };

  const deleteEntity = () => {
    setEntityToEdit(null);

    entitySettings.deleteEntity(entityToDelete).then(() => {
      entitySettings.getEntities(setMasterEntities);
    });
  };

  const deleteAction = (entity) => {
    setEntityToDelete(entity);

    if (entitySettings.deleteDialog) {
      setDeleteDialogOpen(true);
    } else {
      entitySettings.deleteEntity(entity).then(() => {
        entitySettings.getEntities(setMasterEntities);
      });
    }
  };

  const validateInput = (fields) => {
    let result = true;

    entitySettings.validations.forEach((validation) => {
      if (validation.rule(fields[validation.field])) {
        console.log(fields[validation.field], validation.field);
        setErrorMessage({ message: validation.message, field: validation.field });
        result = false;
      }
    });

    return result;
  };

  const openEditEntity = (entity) => {
    setEntityToEdit(entity);

    let fieldsToChange = {};
    entitySettings.columns.forEach((column) => {
      if (column.changeable) {
        fieldsToChange = { ...fieldsToChange, [column.field]: entity[column.field] };
      }
    });
    setEditingFields(fieldsToChange);

    setCreationOpened(false);
  };

  const closeEditEntity = () => {
    setEntityToEdit(null);
    closeErrorMessage();
  };

  const closeErrorMessage = () => {
    setErrorMessage(null);
  };

  const columnClick = (e, column, item) => {
    if (!column.onClick) return;

    column.onClick(item, setHoverableData);

    entityTooltipRef.current.setPos({
      x: e.pageX - 115,
      y: e.pageY + 150 >= document.body.clientHeight ? e.pageY - 135 : e.pageY + 20,
    });

    entityTooltipRef.current.setOpen(true);
  };

  const sortRecords = (records, field, direction) => {
    if (!field) return;

    const sortedRecords = records.sort((a, b) => {
      if (typeof a[field] === "string") {
        if (a[field] < b[field]) {
          return direction === "asc" ? -1 : 1;
        }
        if (a[field] > b[field]) {
          return direction === "asc" ? 1 : -1;
        }
      } else if (typeof a[field] === "number") {
        if (a[field] < b[field]) {
          return direction === "asc" ? -1 : 1;
        }
        if (a[field] > b[field]) {
          return direction === "asc" ? 1 : -1;
        }
      }
      return 0;
    });

    setMasterEntities([...sortedRecords]);
  };

  const keyDownHandler = ({ key }) => {
    if (creationOpened || !!entityToEdit) {
      switch (key) {
        case "Escape":
          if (creationOpened) {
            setCreationOpened(false);
          }
          if (!!entityToEdit) {
            closeEditEntity();
          }
          break;
        case "Enter":
          if (creationOpened) {
            createEntity();
          }
          if (!!entityToEdit) {
            updateEntity();
          }
          break;
      }
    }
  };

  const renderEditingColumn = (column, fields, setFields) => {
    if (!column.changeable) {
      return <div style={column.style} />;
    }

    switch (column.type) {
      case "text":
        return (
          <TeambookTextForm
            id={column.field}
            onChange={(text) => setFields({ ...fields, [column.field]: text })}
            type={"text"}
            fieldValue={fields[column.field]}
            style={{ ...column.style, margin: "0px" }}
            placeholder={t(column.title_translation)}
          />
        );
      case "color":
        return (
          <div style={{ ...column.style }}>
            <div className={column.className} style={{ backgroundColor: fields[column.field] }} />
          </div>
        );
    }
  };

  const renderShowFields = (column, item) => {
    switch (column.type) {
      case "color":
        return (
          <div style={{ ...column.style }}>
            <div className={column.className} style={{ backgroundColor: column.value(item) }} />
          </div>
        );
      default:
        return (
          <p
            onClick={(e) => columnClick(e, column, item)}
            style={{ cursor: column.onClick ? "pointer" : "default", ...column.style }}
          >
            {column.value(item)}
          </p>
        );
    }
  };

  return (
    <Dialog
      onKeyDown={keyDownHandler}
      className="tb-default-dialog"
      open={dialogOpened}
      onClose={() => {
        if (!creationOpened && !entityToEdit) {
          setDialogOpened(false);
        }

        setCreationOpened(false);
        closeEditEntity();
      }}
      aria-labelledby="deactivation-dialog"
      PaperProps={{
        style: {
          width: "620px",
          height: "369px",
          borderRadius: 20,
        },
      }}
      hideBackdrop={deleteDialogOpen}
    >
      <DialogTitle style={{ borderBottom: "none", marginBottom: 0 }}>
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <p>{t(entitySettings.generalWording.header)}</p>

          {entitySettings.createable && (
            <WhiteTooltip title={t(entitySettings.generalWording.newEntity)}>
              <TeambookIcon
                id={"newTeam"}
                name={icons.ADD}
                onClick={() => {
                  closeEditEntity();
                  setCreationOpened(true);
                }}
                alt={"add"}
              />
            </WhiteTooltip>
          )}
        </div>

        <TeambookIcon
          onClick={() => {
            setDialogOpened(false);
            setCreationOpened(false);
            closeEditEntity();
          }}
          name={icons.CROSS}
          alt={"close"}
          color="darkblue_new"
        />
      </DialogTitle>

      <DialogContent style={{ margin: "20px 0px 30px 0px" }}>
        {!masterEntities ? (
          <LoadingComponent />
        ) : (
          <>
            <div className="manage_teams__content-header">
              <div className="flex" style={{ flex: 1, gap: "20px" }}>
                {entitySettings.columns.map((column) => (
                  <div style={column.style}>
                    <p>{t(column.title_translation)}</p>
                  </div>
                ))}
              </div>

              <div style={{ width: 44 * entitySettings.actionsCount + 20 * (entitySettings.actionsCount - 1) }} />
            </div>

            {creationOpened && (
              <>
                <div className="manage-team-dialog__team-row creation" key={"creation"}>
                  <div className="manage-team-dialog__team-text">
                    {entitySettings.columns.map((column) =>
                      renderEditingColumn(column, creationFields, setCreationFields)
                    )}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                      alignItems: "center",
                    }}
                  >
                    <WhiteTooltip title={t("save")}>
                      <TeambookIcon
                        id={"createTeamButton"}
                        name={icons.CHECK}
                        alt={t("create")}
                        onClick={createEntity}
                        className={"manage-team-icon"}
                        color="darkblue_new"
                      />
                    </WhiteTooltip>

                    <WhiteTooltip title={t("cancel")}>
                      <TeambookIcon
                        name={icons.CROSS}
                        alt={t("cancel")}
                        onClick={() => {
                          setCreationOpened(false);
                          closeErrorMessage();
                        }}
                        className={"manage-team-icon"}
                        color="darkblue_new"
                      />
                    </WhiteTooltip>
                  </div>
                </div>

                {!!errorMessage ? (
                  <FormErrorMessage text={errorMessage?.message} closeErrorMessage={closeErrorMessage} />
                ) : null}
              </>
            )}

            {masterEntities.map((item, index) =>
              entityToEdit?.id !== item.id ? (
                <div className="manage-team-dialog__team-row" key={index}>
                  <div className="manage-team-dialog__team-text">
                    {entitySettings.columns.map((column) => renderShowFields(column, item))}
                  </div>

                  <div className="manage-team-dialog__team-actions">
                    <div
                      style={{
                        display: "flex",
                        gap: "20px",
                        alignItems: "center",
                      }}
                    >
                      {entitySettings.editable && (
                        <WhiteTooltip title={t("edit")}>
                          <TeambookIcon
                            id={"editTeam"}
                            onClick={() => openEditEntity(item)}
                            name={icons.PENCIL}
                            color="darkblue_new"
                            className={"manage-team-icon"}
                          />
                        </WhiteTooltip>
                      )}

                      {entitySettings.deletable && (
                        <WhiteTooltip title={t("delete")}>
                          <TeambookIcon
                            onClick={() => deleteAction(item)}
                            name={icons.TRASH}
                            color="darkblue_new"
                            className={"manage-team-icon"}
                          />
                        </WhiteTooltip>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="manage-team-dialog__team-row editing" key={index}>
                    <div className="manage-team-dialog__team-text">
                      {entitySettings.columns.map((column) =>
                        renderEditingColumn(column, editingFields, setEditingFields)
                      )}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        gap: "20px",
                        alignItems: "center",
                      }}
                    >
                      <WhiteTooltip title={t("save")}>
                        <TeambookIcon
                          id={"updateTeamButton"}
                          className={"manage-team-icon"}
                          name={icons.CHECK}
                          onClick={updateEntity}
                          alt={t("save")}
                          color="darkblue_new"
                        />
                      </WhiteTooltip>

                      <WhiteTooltip title={t("cancel")}>
                        <TeambookIcon
                          className={"manage-team-icon"}
                          name={icons.CROSS}
                          onClick={closeEditEntity}
                          alt={t("cancel")}
                          color="darkblue_new"
                        />
                      </WhiteTooltip>
                    </div>
                  </div>

                  {!!errorMessage ? (
                    <FormErrorMessage text={errorMessage?.message} closeErrorMessage={closeErrorMessage} />
                  ) : null}
                </>
              )
            )}
          </>
        )}
      </DialogContent>

      <EntityTooltip hoverableData={hoverableData} ref={entityTooltipRef} />

      <DeleteEntityDialog
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        entityToDelete={entityToDelete}
        deleteEntity={deleteEntity}
        entitySettings={entitySettings}
        teams={masterEntities}
      />
    </Dialog>
  );
};

export default TeambookEntityManagementDialog;
