import ReactSelect, { components } from "react-select";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { SliceStringWithDots } from "../../../lib/StringFormat";
import ArrowTop from "../../default_images/white_arrow_top.svg";
import ArrowBottom from "../../default_images/white_arrow_bottom.svg";
import { useTeambookFilter } from "../../../stores/planner";
import { statuses } from "../../projects/sub_components/lib/statusOptions";
import lsKeys from "../../default_values/defaultKeys";
import { shallow } from "zustand/shallow";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";
import FilterAccessories from "./FilterAccessories";
import advancedFilterOptions from "./advancedFilterOptions";
import filterSettings from "./filterSettings";
import SavedFilterMenu from "./filter_storage/SavedFilterMenu";
import $ from "jquery";

const getRoles = (t) => {
  return [
    { id: "regular", name: t("users.regular") },
    { id: "contractor", name: t("users.contractor") },
    { id: "self_planner", name: t("users.self_planner") },
    { id: "planner", name: t("users.planner") },
    { id: "admin", name: t("users.admin") },
  ];
};

const getBillability = (t) => {
  return [
    { id: true, name: t("users.billable") },
    { id: false, name: t("users.non_billable") },
  ];
};

const projectTypes = (t) => {
  return [
    { id: "billable", name: t("projects.billable") },
    { id: "non_billable", name: t("projects.non_billable") },
    { id: "time_off", name: t("planning.time_off") },
  ];
};

const TeambookFilterComponent = ({
  users,
  clients,
  projects,
  tags,
  departments,
  teams,
  managers,
  businessUnits,
  tasks,
  className,
  filterPage,
}) => {
  const { t } = useTranslation();
  const [filterOptions, setFilterOptions] = useState([]);
  const [screenWidth, setScreenWidth] = useState(document.body.clientWidth);
  const [storageOptionsOpened, setStorageOptionsOpened] = useState(false);
  const [storageOptionsAnchor, setStorageOptionsAnchor] = useState();
  const plannerType = localStorage.getItem(lsKeys.LAST_PLANNER_TYPE);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const selectRef = useRef();
  const [inputValue, setInputValue] = useState("");
  const ref = useRef();

  const version = screenWidth < 640 ? "mobile" : screenWidth >= 640 && screenWidth < 1100 ? "tablet" : "desktop";
  const lastFilterKey = filterSettings(t)[filterPage].filterKey;

  const [filterValues, setFilterValue, filterType, switchFilterType] = useTeambookFilter(
    (state) => [state.filterValues, state.setFilterValue, state.filterType, state.switchFilterType],
    shallow
  );

  useEffect(() => {
    if (JSON.parse(localStorage.getItem(lastFilterKey)) && JSON.parse(localStorage.getItem(lastFilterKey)).length > 0) {
      setFilterValue(JSON.parse(localStorage.getItem(lastFilterKey)) || []);
    } else {
      setFilterValue([]);
    }
  }, [lastFilterKey]);

  useEffect(() => {
    const optionArr = [];

    // TEAMS
    const optionTeams = teams.map((item) => {
      return {
        name: item.name,
        id: item.id,
        team_users: item.team_users.map((u) => u.id),
        filter_type: "team",
      };
    });

    optionArr.push({
      type: t("planning.filter_teams"),
      names: optionTeams,
    });

    // USERS
    const optionUsers = users.map((user) => ({
      name: `${user.first_name} ${user.last_name}`,
      id: user.id,
      filter_type: "user",
    }));
    optionArr.push({ type: t("planning.filter_users"), names: optionUsers });

    // TAGS

    const optionTags = tags.map((item) => ({
      name: item.name,
      id: item.id,
      tag_users: item.tag_users,
      filter_type: "tag",
    }));
    optionArr.push({ type: t("planning.filter_tags"), names: optionTags });

    // PROJECTS
    const optionProjects = projects.map((item) => {
      return {
        name: item.name,
        id: item.id,
        kind: item.kind,
        filter_type: "project",
      };
    });

    optionArr.push({
      type: t("planning.filter_projects"),
      names: optionProjects,
    });

    // CLIENTS
    const formedClients = clients
      .filter((client) => client.active)
      .map((item) => ({
        name: item.name,
        id: item.id,
        filter_type: "client",
      }));
    optionArr.push({
      type: t("planning.filter_clients"),
      names: formedClients,
    });

    // MANAGERS
    const formedManagers = managers.map((item) => ({
      name: item.name,
      id: item.id,
      filter_type: "project_manager",
    }));
    optionArr.push({
      type: t("projects.manager"),
      names: formedManagers,
    });

    // STATUSES
    const formedStatuses = statuses(t).map((item) => ({
      name: item,
      id: item,
      filter_type: "project_status",
    }));
    optionArr.push({
      type: t("projects.status"),
      names: formedStatuses,
    });

    // BUSINESS_UNITS
    const formedBU = businessUnits.map((item) => ({
      name: item,
      id: item,
      filter_type: "business_unit",
    }));
    optionArr.push({
      type: t("projects.business_unit"),
      names: formedBU,
    });

    // TASKS
    const formedTasks = tasks?.map((item) => ({
      name: item.name,
      id: item.id,
      filter_type: "tasks",
    }));

    optionArr.push({
      type: t("planning.filter_tasks"),
      names: formedTasks || [],
    });

    const formedRoles = getRoles(t)?.map((item) => ({
      name: item.name,
      id: item.id,
      filter_type: "authorization",
    }));

    optionArr.push({
      type: t("planning.filter_authorization"),
      names: formedRoles || [],
    });

    const formedBillability = getBillability(t)?.map((item) => ({
      name: item.name,
      id: item.id,
      filter_type: "billability",
    }));

    optionArr.push({
      type: t("planning.filter_billability"),
      names: formedBillability || [],
    });

    // ADVANCED
    optionArr.push({
      type: t("planning.filter_advanced"),
      names: advancedFilterOptions(plannerType, t),
    });

    // PROJECT TYPES
    optionArr.push({
      type: t("projects.type"),
      names: projectTypes(t),
    });

    // DEPARTMENTS
    const formedDepartments = departments?.map((item) => ({
      name: item.name,
      id: item.id,
      filter_type: "department",
    }));
    optionArr.push({
      type: t("users.department"),
      names: formedDepartments || [],
    });

    setFilterOptions(optionArr);
  }, [users, tags, projects, clients, managers, businessUnits, tasks]);

  const reformatedCompanies = filterOptions.reduce((item, row) => {
    const companyRows = [];
    const type = row.type;

    row.names.map((name) => {
      companyRows.push({
        type: type,
        name: name.name,
        label: name.name,
        id: name.id,
        value: name.id,
        kind: name.kind || "",
        team_users: name.team_users || [],
        tag_users: name.tag_users || [],
        filter_type: name.filter_type,
      });
    });

    item = item.concat(companyRows);

    const filterAkku = item.filter(function (item) {
      const getName = filterValues.map((item) => item.name);
      return !getName.includes(item.name);
    });

    return filterAkku;
  }, []);

  const changeFilterType = (e) => {
    switchFilterType();
  };

  const sortProjects = (arr) => {
    let time_offs = [];
    let non_time_offs = [];
    arr.forEach((proj) => (proj.kind === "time_off" ? time_offs.push(proj) : non_time_offs.push(proj)));

    const alphabeticalySortProjects = (a, b) => {
      return a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1;
    };

    return [...non_time_offs.sort(alphabeticalySortProjects), ...time_offs.sort(alphabeticalySortProjects)];
  };

  const sortAlpha = (arr) => {
    return arr.sort((a, b) => {
      if (a.name.toUpperCase() < b.name.toUpperCase()) {
        return -1;
      }
      if (a.name.toUpperCase() > b.name.toUpperCase()) {
        return 1;
      }
      return 0;
    });
  };

  const usersGroup = reformatedCompanies.filter((el) => {
    return el.type === t("planning.filter_users");
  });

  const tagsGroup = reformatedCompanies.filter((el) => {
    return el.type === t("planning.filter_tags");
  });

  const projectsGroup = reformatedCompanies.filter((el) => {
    return el.type === t("planning.filter_projects");
  });

  const advancedGroup = reformatedCompanies.filter((el) => {
    return el.type === t("planning.filter_advanced");
  });

  const clientsGroup = reformatedCompanies.filter((el) => {
    return el.type === t("planning.filter_clients");
  });

  const departmentsGroup = reformatedCompanies.filter((el) => {
    return el.type === t("users.department");
  });

  const teamsGroup = reformatedCompanies.filter((el) => {
    return el.type === t("planning.filter_teams");
  });

  const managersGroup = reformatedCompanies.filter((el) => {
    return el.type === t("projects.manager");
  });

  const statusesGroup = reformatedCompanies.filter((el) => {
    return el.type === t("projects.status");
  });

  const businessUnitsGroup = reformatedCompanies.filter((el) => {
    return el.type === t("projects.business_unit");
  });

  const tasksGroup = reformatedCompanies.filter((el) => {
    return el.type === t("planning.filter_tasks");
  });

  const rolesGroup = reformatedCompanies.filter((el) => {
    return el.type === t("planning.filter_authorization");
  });

  const billabilityGroup = reformatedCompanies.filter((el) => {
    return el.type === t("planning.filter_billability");
  });

  const projectTypeGroup = reformatedCompanies.filter((el) => {
    return el.type === t("projects.type");
  });

  const groupedOptions = filterSettings(t)[filterPage].filterFields.map((field) => {
    switch (field) {
      case t("planning.filter_users"):
        return {
          label: t("planning.filter_users"),
          options: sortAlpha([...usersGroup]),
          color: "#78A0C8",
        };
      case t("planning.filter_tags"):
        return {
          label: t("planning.filter_tags"),
          options: sortAlpha([...tagsGroup]),
          color: "#7B8ACA",
        };
      case t("planning.filter_projects"):
        return {
          label: t("planning.filter_projects"),
          options: sortProjects([...projectsGroup]),
          color: "#867ECD",
        };
      case t("planning.filter_clients"):
        return {
          label: t("planning.filter_clients"),
          options: sortAlpha([...clientsGroup]),
          color: "#A08DCF",
        };
      case t("planning.filter_advanced"):
        return {
          label: t("planning.filter_advanced"),
          options: advancedGroup,
          color: "#BA83D1",
        };
      case t("planning.filter_teams"):
        return {
          label: t("planning.filter_teams"),
          options: sortAlpha([...teamsGroup]),
          color: "#7B8ACA",
        };
      case t("projects.manager"):
        return {
          label: t("projects.manager"),
          options: sortAlpha([...managersGroup]),
          color: "#7B8ACA",
        };
      case t("projects.status"):
        return {
          label: t("projects.status"),
          options: sortAlpha([...statusesGroup]),
          color: "#78A0C8",
        };
      case t("projects.business_unit"):
        return {
          label: t("projects.business_unit"),
          options: sortAlpha([...businessUnitsGroup]),
          color: "#7B8ACA",
        };
      case t("projects.type"):
        return {
          label: t("projects.type"),
          options: projectTypeGroup,
          color: "#7B8ACA",
        };
      case t("users.department"):
        return {
          label: t("users.department"),
          options: sortAlpha([...departmentsGroup]),
          color: "#A08DCF",
        };
      case t("planning.filter_tasks"):
        return {
          label: t("projects.tasks"),
          options: sortAlpha([...tasksGroup]),
          color: "#7B8ACA",
        };
      case t("planning.filter_authorization"):
        return {
          label: t("users.authorization"),
          options: rolesGroup,
          color: "#7B8ACA",
        };
      case t("planning.filter_billability"):
        return {
          label: t("planning.filter_billability"),
          options: billabilityGroup,
          color: "#7B8ACA",
        };
    }
  });

  const calculateOffsetLeft = () => {
    if (isMenuOpen) {
      const menuWidth = $(".planner-filter-row__menu-list").width();
      const selectWidth = selectRef?.current?.clientWidth;

      const calculatedLeftOffset = -(menuWidth - selectWidth) / 2;

      if (Math.abs(calculatedLeftOffset) > $(selectRef.current).offset().left) {
        return 0;
      } else {
        return calculatedLeftOffset;
      }
    }
  };

  const styles = {
    groupHeading: (base, { data }) => ({
      ...base,
      background: data.color,
    }),

    menu: (base, data) => ({
      ...base,
      width: "fit-content",
      maxWidth: "1000px",
      left: isMenuOpen && calculateOffsetLeft(),
    }),

    group: (base) => ({
      ...base,
      width: "180px !important",
      maxWidth: "180px !important",
    }),

    option: (base) => ({
      ...base,
      color: "var(--text-2) !important",

      "&:hover": {
        background: "var(--stroke)",
      },
    }),
  };

  const formatOptionLabel = ({ name, type }) => {
    const iconColor = "darkblue_new";
    return (
      <div className="planner-filter-row__option-value-container">
        {type === t("planning.filter_advanced") ? (
          <div className="advanced-icon label-icon">
            <TeambookIcon
              color={iconColor}
              className="planner-filter-row__option-icon"
              name={
                ["userPlanner", "groupedPlanner", "dashboard"].includes(filterPage)
                  ? icons.ADVANCED_SETTINGS
                  : icons.ADVANCED_FILTER
              }
            />
          </div>
        ) : null}
        {type === t("planning.filter_clients") ? (
          <div className="clients-icon label-icon">
            <TeambookIcon color={iconColor} className="planner-filter-row__option-icon" name={icons.CLIENTS} />
          </div>
        ) : null}
        {type === t("planning.filter_tags") ? (
          <div className="tags-icon label-icon">
            <TeambookIcon color={iconColor} className="planner-filter-row__option-icon" name={icons.TAGS} />
          </div>
        ) : null}
        {type === t("planning.filter_projects") ? (
          <div className="projects-icon label-icon">
            <TeambookIcon color={iconColor} className="planner-filter-row__option-icon" name={icons.PROJECT} />
          </div>
        ) : null}
        {type === t("planning.filter_users") ? (
          <div className="users-icon label-icon">
            <TeambookIcon color={iconColor} className="planner-filter-row__option-icon" name={icons.USERS} />
          </div>
        ) : null}
        {type === t("planning.filter_teams") ? (
          <div className="users-icon label-icon">
            <TeambookIcon color={iconColor} className="planner-filter-row__option-icon" name={icons.TEAM_USERS} />
          </div>
        ) : null}
        {type === t("projects.manager") ? (
          <div className="users-icon label-icon">
            <TeambookIcon color={iconColor} className="planner-filter-row__option-icon" name={icons.USERS} />
          </div>
        ) : null}
        {type === t("projects.status") ? (
          <div className="users-icon label-icon">
            <TeambookIcon color={iconColor} className="planner-filter-row__option-icon" name={icons.USERS} />
          </div>
        ) : null}
        {type === t("projects.business_unit") ? (
          <div className="tags-icon label-icon">
            <TeambookIcon color={iconColor} className="planner-filter-row__option-icon" name={icons.TAGS} />
          </div>
        ) : null}
        {type === t("planning.filter_tasks") ? (
          <div className="tags-icon label-icon">
            <TeambookIcon color={iconColor} className="planner-filter-row__option-icon" name={icons.TASKS} />
          </div>
        ) : null}
        {type === t("planning.filter_authorization") ? (
          <div className="tags-icon label-icon">
            <TeambookIcon className="planner-filter-row__option-icon" name={icons.USERS} />
          </div>
        ) : null}
        {type === t("planning.filter_billability") ? (
          <div className="tags-icon label-icon">
            <TeambookIcon className="planner-filter-row__option-icon" name={icons.USERS} />
          </div>
        ) : null}

        <p className="planner-filter-row__option-value">{SliceStringWithDots(name, 28)}</p>
      </div>
    );
  };

  const handleHeaderClick = (id) => {
    if (version === "mobile" || version === "tablet") {
      const selectGroup = document.querySelector(`#${id}`).parentElement.nextElementSibling;
      if (getComputedStyle(selectGroup).display === "none") {
        selectGroup.style.display = "block";
      } else {
        selectGroup.style.display = "none";
      }
    }
  };

  const CustomGroupHeading = (props) => {
    const [isOpen, setOpen] = useState(false);
    return (
      <div
        className="group-heading-wrapper"
        onClick={() => {
          handleHeaderClick(props.id);
          setOpen(!isOpen);
        }}
      >
        <components.GroupHeading {...props}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {props.data.label}
            <img
              alt={"dropdown-arrow"}
              style={{ width: "30px", height: "30px" }}
              src={isOpen ? ArrowTop : ArrowBottom}
            />
          </div>
        </components.GroupHeading>
      </div>
    );
  };

  const CustomGroup = (props) => {
    return <components.Group className="Group" {...props} />;
  };

  const MultiValueRemove = (props) => {
    return (
      <components.MultiValueRemove {...props}>
        <TeambookIcon className="planner-filter-row__option-icon" name={icons.CROSS} color="gray" />
      </components.MultiValueRemove>
    );
  };

  const Menu = (props) => {
    const isNoOptions = !props.options
      .map((opt) => opt.options)
      .flat()
      .find(({ name }) => name.toLowerCase().includes(props.selectProps.inputValue.toLowerCase()));

    return (
      <components.Menu {...props} className={isNoOptions ? "react-select__no-options-menu" : ""}>
        {props.children}
      </components.Menu>
    );
  };

  const emptyAriaLiveMessages = {
    guidance: () => "",
    onChange: () => "",
    onFilter: () => "",
    onFocus: () => "",
  };

  return (
    <div className={`planner-filter-row ${className || ""}`}>
      <div ref={selectRef} className="planner-filter-row__filter-copmonent">
        <ReactSelect
          ref={ref}
          inputValue={inputValue}
          onInputChange={setInputValue}
          classNamePrefix="planner-filter-row"
          className="planner-filter-row__select"
          styles={styles}
          isMulti
          autosize={false}
          closeMenuOnSelect={false}
          blurInputOnSelect={false}
          formatOptionLabel={formatOptionLabel}
          onChange={(options, newValue) => {
            localStorage.setItem(lastFilterKey, JSON.stringify(options || []));

            setFilterValue(Array.isArray(options) ? options.map((x) => x) : []);
          }}
          value={filterValues}
          options={groupedOptions}
          placeholder={t("planning.filter_text")}
          filterType={filterType}
          changeFilterType={changeFilterType}
          enableSortTypes={filterSettings(t)[filterPage].orAndSwitch}
          enableSavedFilter={filterSettings(t)[filterPage].saveFilter}
          closeDropdown={() => {
            setIsMenuOpen(false);
          }}
          noOptionsMessage={() => t("planning.filter.no_options")}
          components={
            version !== "desktop"
              ? {
                  GroupHeading: CustomGroupHeading,
                  Group: CustomGroup,
                  MultiValueRemove: MultiValueRemove,
                }
              : {
                  MultiValueRemove: MultiValueRemove,
                  ValueContainer: FilterAccessories,
                  Menu: Menu,
                }
          }
          menuIsOpen={isMenuOpen}
          onMenuOpen={() => setIsMenuOpen(true)}
          onMenuClose={() => setIsMenuOpen(false)}
          setStorageOptionsOpened={setStorageOptionsOpened}
          setStorageOptionsAnchor={setStorageOptionsAnchor}
          ariaLiveMessages={emptyAriaLiveMessages}
        />
      </div>

      <SavedFilterMenu
        storageOptionsAnchor={storageOptionsAnchor}
        storageOptionsOpened={storageOptionsOpened}
        setStorageOptionsOpened={setStorageOptionsOpened}
        filterPage={filterPage}
      />
    </div>
  );
};

// Why do we need this?
// React in case of default props being empty array and having useEffect on that prop will always run useEffect,
// since React thinks that [] and [] is different objects and it needs to refresh.
TeambookFilterComponent.defaultProps = {
  managers: [],
  clients: [],
  teams: [],
  businessUnits: [],
};

export default TeambookFilterComponent;
