import React, { useState, useRef } from "react";
import "../../../default_styles/tb-colors.css";
import { useTranslation } from "react-i18next";
import FormErrorMessage from "../../../default_components/FormErrorMessage";
import { WhiteTooltip } from "./../../../default_components/Tooltips";
import TeambookColorPicker from "./../../../default_components/TeambookColorPicker";
import TeambookTextForm from "../../../default_components/TeambookTextForm";
import { icons } from "../../../default_images/IconsList";
import { TeambookIcon } from "../../../default_images/TeambookIcon";

function EditTag({
  tag,
  closeErrorMessage,
  updateTag,
  tagName,
  setTagName,
  tagColor,
  setTagColor,
  errorMessage,
  handleCloseEditing,
}) {
  const { t } = useTranslation();

  const [changeColorsOpened, setChangeColorsOpened] = useState(false);
  const anchorRef = useRef(null);

  if (tag.id === undefined) {
    return <div />;
  }

  return (
    <>
      <div className="tag-menu__tag-editing">
        <div style={{ width: 70, padding: "0px 23px" }}>
          <WhiteTooltip title={t("projects.change_color")}>
            <div
              className="tags-color-circle"
              style={{ border: `2px solid ${tagColor}`, backgroundColor: `${tagColor}66`, cursor: "pointer" }}
              onClick={() => setChangeColorsOpened(true)}
              ref={anchorRef}
            />
          </WhiteTooltip>
        </div>

        <TeambookColorPicker
          color={tagColor}
          setColor={setTagColor}
          changeColorsOpened={changeColorsOpened}
          setChangeColorsOpened={setChangeColorsOpened}
          anchorRef={anchorRef}
        />

        <div>
          <TeambookTextForm
            id={"tagName"}
            width={200}
            fieldValue={tagName}
            onChange={setTagName}
            autoFocus
            placeholder={t("users.tag_name")}
          />
        </div>

        <div className="tag-menu__tag-number">
          <p style={{ margin: 0 }}>{tag.tag_users.length}</p>
        </div>

        <div
          style={{
            paddingLeft: 68,
            display: "flex",
            gap: 20,
          }}
        >
          <TeambookIcon
            className={"manage-tags-icon"}
            id={"saveEditingTag"}
            name={icons.CHECK}
            tooltipTitle={t("save")}
            alt={t("save")}
            onClick={updateTag}
            color="darkblue_new"
          />

          <TeambookIcon
            className={"manage-tags-icon"}
            name={icons.CROSS}
            onClick={handleCloseEditing}
            tooltipTitle={t("close")}
            color="darkblue_new"
          />
        </div>
      </div>
      <div style={{ paddingTop: errorMessage !== null ? 15 : 0 }}>
        {errorMessage !== null ? <FormErrorMessage text={errorMessage} closeErrorMessage={closeErrorMessage} /> : null}
      </div>
    </>
  );
}

export default EditTag;
