import React from "react";
import "./default_styles.scss";
import { TeambookIcon } from "../default_images/TeambookIcon";

const TeambookOptionsLine = ({ options, selectedOption }) => {
  return (
    <div className="teambook-options-line">
      {options.map((option, index) => (
        <div
          key={index}
          className={`teambook-options-line__option ${selectedOption === option.value ? "option-active" : ""}`}
          onClick={option.onClick}
        >
          {option.icon}
          <p>{option.text}</p>
        </div>
      ))}
    </div>
  );
};

export default TeambookOptionsLine;
