import UsersProfileImage from "../UsersProfileImage";
import Api from "../../../Api";

const entityMapping = {
  teams: {
    createable: true,
    editable: true,
    deletable: true,
    deleteDialog: true,
    searchable: false,
    actionsCount: 2,

    generalWording: {
      header: "users.selected_fields.teams",
      newEntity: "planning.new_team",
    },

    defaultCreationValues: () => {
      return {};
    },

    columns: [
      {
        field: "name",
        title_translation: "planning.team_name",
        value: (team) => team.name,
        changeable: true,
        style: { width: "70%" },
        type: "text",
      },
      {
        field: "users_count",
        title_translation: "users.tag_users",
        value: (team) => team.team_users.length,
        onClick: (team, setHoverableData) =>
          setHoverableData(
            team.team_users.map((user) => (
              <div style={{ display: "flex", gap: "10px" }}>
                <UsersProfileImage size={24} user={user} userImage={user.avatars["24"]} />
                <p style={{ marginBottom: "0px" }}>{user.name}</p>
              </div>
            ))
          ),
        changeable: false,
        style: { width: "30%" },
        type: "number",
      },
    ],

    getEntities: (setEntities) => {
      Api.Teams.all().then((response) => {
        setEntities(response.data);
      });
    },

    deleteEntity: (entity) => {
      return Api.Teams.delete(entity.id);
    },

    create: (fields) => {
      return Api.Teams.create(fields.name);
    },

    update: (entity, fields) => {
      return Api.Teams.update(
        entity.id,
        fields.name,
        entity.team_users.map((user) => user.id)
      );
    },

    validations: [
      {
        field: "name",
        rule: (value) => !value || value.length < 3 || value.length > 30,
        message: "Must be between 3 and 30 characters long",
      },
    ],
  },

  departments: {
    createable: true,
    editable: true,
    deletable: true,
    deleteDialog: false,
    searchable: false,
    actionsCount: 2,

    generalWording: {
      header: "users.departments",
      newEntity: "planning.new_team",
    },

    defaultCreationValues: () => {
      return {
        color: ["#aec7e8", "#ffbb78", "#98df8a", "#ff9896", "#c5b0d5"][Math.floor(Math.random() * 5)],
      };
    },

    columns: [
      {
        field: "color",
        title_translation: "projects.color",
        value: (department) => department.color,
        changeable: true,
        style: { width: "75px", display: "flex", justifyContent: "center" },
        type: "color",
        className: "departments-color-box",
      },
      {
        field: "name",
        title_translation: "users.department_name",
        value: (department) => department.name,
        changeable: true,
        style: { width: "200px" },
        type: "text",
      },
      {
        field: "users_count",
        title_translation: "users.tag_users",
        value: (department) => department.users_count,
        // onClick: (team, setHoverableData) =>
        //   setHoverableData(
        //     team.team_users.map((user) => (
        //       <div style={{ display: "flex", gap: "10px" }}>
        //         <UsersProfileImage size={24} user={user} userImage={user.avatars["24"]} />
        //         <p style={{ marginBottom: "0px" }}>{user.name}</p>
        //       </div>
        //     ))
        //   ),
        changeable: false,
        style: { width: "65px" },
        type: "number",
      },
    ],

    getEntities: (setEntities) => {
      Api.Departments.all().then((response) => {
        setEntities(response.data);
      });
    },

    deleteEntity: (entity) => {
      return Api.Departments.delete(entity.id);
    },

    create: (fields) => {
      return Api.Departments.create(fields.name, fields.color);
    },

    update: (entity, fields) => {
      return Api.Departments.update(entity.id, fields.name, fields.color);
    },

    validations: [
      {
        field: "name",
        rule: (value) => !value || value.length < 3 || value.length > 30,
        message: "Must be between 3 and 30 characters long",
      },
    ],
  },
};

export default entityMapping;
