import PlannerActions from "./PlannerActions";
import {
  useBookingsCreatingStore,
  useBookingsStore,
  useCopyBookingsStore,
  useDateStore,
  useSelectedProjectStore,
  useShiftPressedStore,
  useZoomStore,
} from "../../../../stores/planner";
import $ from "jquery";
import Api from "../../../../Api";
import { getCellWidthForZoom, getNumberOfDays, OffsetDays, ToDateString } from "../../../../lib/DateUtils";
import { usersMaxScheduleTime } from "../../../../lib/PlannerUtils";
import { useAccountStore } from "../../../../stores/accountStore";

class GroupedPlannerActions extends PlannerActions {
  mouseUped(
    offsetX,
    offsetY,
    users,
    e,
    multipleSelectRef,
    selectBooking,
    plannerDispatcher,
    usersRef,
    teams,
    teamId,
    closeEditWindow,
    openCreationWindow,
    groupClients,
    setSelectedProject,
    groupedProjectUsers,
    bookingFormat,
    shownProjects,
    activeProjects,
    projects,
    setProjectsType
  ) {
    if (groupClients) {
      offsetX -= 50;
    }

    if (useShiftPressedStore.getState().shiftPressed || useShiftPressedStore.getState().startSelected) {
      if (!multipleSelectRef || !multipleSelectRef.current) return;

      multipleSelectRef.current.setOpen(false);
      multipleSelectRef.current.setPos({ x: 0, y: 0 });
      multipleSelectRef.current.setSize({ width: 0, height: 0 });
      let clientsOffset = groupClients ? 50 : 0;

      let clientY = e?.clientY - 209 || 0;

      let bookingsPosition = Array.from($(".booking")).map((b) => {
        return {
          left: $(b).offset().left + $("#planner-scroll-list").scrollLeft() - 155,
          right: $(b).offset().left + $("#planner-scroll-list").scrollLeft() + $(`#${b.id}`).width() - 155,
          top: $(b).offset().top + $("#planner-scroll-list").scrollTop() - 209,
          id: b.id,
          bottom: $(b).offset().top + $(b).height() + $("#planner-scroll-list").scrollTop() - 209,
        };
      });

      bookingsPosition.forEach((booking) => {
        if (
          ((booking.left >= useShiftPressedStore.getState().startX &&
            booking.left <= offsetX + clientsOffset + $("#planner-scroll-list").scrollLeft()) ||
            (booking.right >= useShiftPressedStore.getState().startX &&
              booking.right <= offsetX + clientsOffset + $("#planner-scroll-list").scrollLeft())) &&
          ((booking.top >= useShiftPressedStore.getState().startY &&
            booking.top <= clientY + $("#planner-scroll-list").scrollTop()) ||
            (booking.bottom >= useShiftPressedStore.getState().startY &&
              booking.bottom <= clientY + $("#planner-scroll-list").scrollTop()))
        ) {
          selectBooking(this.getBookingById(+booking.id.split("-")[1]));
        }
      });

      useShiftPressedStore.getState().setStartSelected(false);
    } else {
      offsetY += $("#planner-scroll-list").scrollTop();
      const project = this.getCreationProject(
        e,
        groupedProjectUsers,
        bookingFormat,
        shownProjects,
        activeProjects,
        projects
      );

      if (
        this.userIndexStart !== null &&
        e.button === 0 &&
        !e.ctrlKey &&
        !useCopyBookingsStore.getState().copyModeActive
      ) {
        openCreationWindow();
        setSelectedProject(project);
      }

      offsetX -= 35;

      if (useCopyBookingsStore.getState().copyModeActive && useCopyBookingsStore.getState().copyBookingIds.length > 0) {
        if (this.clickStartX === offsetX && this.clickStartY === offsetY && e.button === 0) {
          let new_bookings = useBookingsStore.getState().bookings;

          Api.Bookings.copy(
            useCopyBookingsStore.getState().copyBookingIds,
            users[this.userIndexStart].id,
            ToDateString(this.userDateStart)
          ).then((response) => {
            useBookingsStore.getState().setBookings([...new_bookings, ...response.data]);

            let userWithBookingsUpdated = groupedProjectUsers
              .flat()
              .filter((user, index) => index === this.userIndexStart)
              .map((u) => u.id);

            userWithBookingsUpdated = groupedProjectUsers
              .flat()
              .map((user, i) => (userWithBookingsUpdated.includes(user.id) ? i : null))
              .filter((u) => u !== null);

            plannerDispatcher.afterCreationBookingAssignment(userWithBookingsUpdated);

            this.userIndexStart = null;
            this.userDateStart = null;
          });
        }
      } else {
        if (this.clickStartX === offsetX && this.clickStartY === offsetY && e.button === 0) {
          if (this.shiftCreation) {
            this.shiftCreation = false;
            useBookingsCreatingStore.getState().setBookings([[this.userIndexStart, this.userDateStart]]);
          } else {
            useBookingsCreatingStore.getState().addBooking([this.userIndexStart, this.userDateStart]);
          }

          useBookingsCreatingStore
            .getState()
            .setDuration(useAccountStore.getState().account?.default_booking_duration / 60);
          closeEditWindow();
          openCreationWindow();

          setSelectedProject(project);
          setProjectsType(project?.kind === "time_off" ? "time_off" : "default");
        }

        this.userIndexStart = null;
        this.userDateStart = null;
      }

      useShiftPressedStore.getState().setStartSelected(false);
      useShiftPressedStore.getState().setShiftPressed(false);
    }

    super.mouseUped(offsetX, offsetY, users, e);
  }

  mouseMoved(
    e,
    offsetX,
    offsetY,
    users,
    weekendsHidden,
    multipleSelectRef,
    bookingFormat,
    groupClients,
    groupedProjectUsers,
    zoom,
    projectRowOffset,
    closeEditWindow,
    shownProjects,
    activeProjects,
    projects
  ) {
    if (groupClients) {
      offsetX -= 50;
    }

    if (useShiftPressedStore.getState().shiftPressed || useShiftPressedStore.getState().startSelected) {
      let _height = 0;
      const userHeights = users.map(
        (user) => (usersMaxScheduleTime(user) / 60) * this.formatMapping[bookingFormat] + 17
      );
      let clientsOffset = groupClients ? 50 : 0;

      userHeights.forEach((userHeight, index) => {
        if (
          _height < offsetY + $("#planner-scroll-list").scrollTop() &&
          _height + userHeight > offsetY + $("#planner-scroll-list").scrollTop()
        ) {
          this.userIndexEnd = index;

          multipleSelectRef.current.setPos({
            x: useShiftPressedStore.getState().startX,
            y: useShiftPressedStore.getState().startY,
          });

          const weekendWidth = weekendsHidden ? 10 : getCellWidthForZoom(zoom);
          const dayWidth = getCellWidthForZoom(zoom);

          let calculatedOffsetX = 0;
          let dateIteration = 0;

          while (calculatedOffsetX < offsetX + $("#planner-scroll-list").scrollLeft()) {
            calculatedOffsetX += dateIteration % 7 < 5 ? dayWidth : weekendWidth;
            dateIteration++;
          }

          multipleSelectRef.current.setSize({
            width:
              offsetX + $("#planner-scroll-list").scrollLeft() - useShiftPressedStore.getState().startX + clientsOffset,
            height: offsetY + $("#planner-scroll-list").scrollTop() - useShiftPressedStore.getState().startY,
          });

          this.userDateEnd = OffsetDays(useDateStore.getState().date, dateIteration - 1);
        }

        _height += userHeight;
      });
    } else {
      if (this.userIndexStart === null || useCopyBookingsStore.getState().copyModeActive) {
        return null;
      }

      const project = this.getCreationProject(
        e,
        groupedProjectUsers,
        bookingFormat,
        shownProjects,
        activeProjects,
        projects
      );

      multipleSelectRef.current.setPos({ x: 0, y: 0 });
      multipleSelectRef.current.setSize({ width: 0, height: 0 });

      let users = groupedProjectUsers.flat();
      offsetY += $("#planner-scroll-list").scrollTop();

      offsetX -= 35;

      const weekendWidth = weekendsHidden ? 10 : getCellWidthForZoom(zoom);
      const dayWidth = getCellWidthForZoom(zoom);
      let _height = 0;
      const userHeights = users.map(
        (user) => (usersMaxScheduleTime(user) / 60) * this.formatMapping[bookingFormat] + 17
      );

      userHeights.forEach((userHeight, index) => {
        if (
          _height + projectRowOffset(offsetY) < offsetY &&
          _height + userHeight + projectRowOffset(offsetY) > offsetY
        ) {
          this.userIndexEnd = index;

          let calculatedOffsetX = 0;
          let dateIteration = 0;

          while (calculatedOffsetX < offsetX + $("#planner-scroll-list").scrollLeft()) {
            calculatedOffsetX += dateIteration % 7 < 5 ? dayWidth : weekendWidth;
            dateIteration++;
          }

          this.userDateEnd = OffsetDays(useDateStore.getState().date, dateIteration - 1);
        }

        _height += userHeight;
      });

      let currentHeight = 0;

      for (let i = 0; i < this.userIndexEnd; i++) {
        currentHeight += userHeights[i];
      }

      let currentBookingsHeight = 0;
      let currentBookings = useBookingsStore
        .getState()
        .bookings.filter(
          (booking) =>
            booking.user_id === users[this.userIndexEnd].id &&
            booking.date === ToDateString(this.userDateEnd) &&
            booking.deleted_at === null
        );

      currentBookings.forEach((booking) => {
        currentBookingsHeight += (booking.duration / 60) * this.formatMapping[bookingFormat];
      });

      let newDuration =
        (offsetY - projectRowOffset(offsetY) - currentHeight - currentBookingsHeight) /
        this.formatMapping[bookingFormat];

      newDuration = +(Math.round(newDuration * 2) / 2).toFixed(2);

      useBookingsCreatingStore.getState().setBookings(this.redrawCreationBookings());
      useBookingsCreatingStore.getState().setDuration(newDuration);
      closeEditWindow();

      if (project?.kind === "time_off") {
        useSelectedProjectStore.getState().setSelectedTimeOff(project);
        useSelectedProjectStore.getState().setProjectsType("time_off");
      } else {
        useSelectedProjectStore.getState().setSelectedProject(project);
        useSelectedProjectStore.getState().setProjectsType("default");
      }
    }
  }

  mouseDowned(
    offsetX,
    offsetY,
    groupedProjectUsers,
    multipleSelectRef,
    weekendsHidden,
    bookingFormat,
    groupClients,
    projectRowOffset
  ) {
    offsetX -= 35;

    if (groupClients) {
      offsetX -= 50;
    }

    if (useShiftPressedStore.getState().shiftPressed) {
      useShiftPressedStore.getState().setStartSelected(true);
      multipleSelectRef.current.setOpen(false);
      multipleSelectRef.current.setPos({ x: 0, y: 0 });
      multipleSelectRef.current.setSize({ width: 0, height: 0 });
      let clientsOffset = groupClients ? 50 : 0;

      useShiftPressedStore.getState().setStartX(offsetX + $("#planner-scroll-list").scrollLeft() + clientsOffset);
      useShiftPressedStore.getState().setStartY(offsetY + $("#planner-scroll-list").scrollTop());

      multipleSelectRef.current.setPos({
        x: offsetX + $("#planner-scroll-list").scrollLeft() + clientsOffset,
        y: offsetY + $("#planner-scroll-list").scrollTop(),
      });

      multipleSelectRef.current.setOpen(true);
    } else {
      const weekendWidth = weekendsHidden ? 10 : getCellWidthForZoom(useZoomStore.getState().zoom);
      const dayWidth = getCellWidthForZoom(useZoomStore.getState().zoom);
      let currentHeight = 0;
      const users = groupedProjectUsers.flat();
      const userHeights = users.map(
        (user) => (usersMaxScheduleTime(user) / 60) * this.formatMapping[bookingFormat] + 17
      );

      offsetY += $("#planner-scroll-list").scrollTop();

      this.clickStartX = offsetX;
      this.clickStartY = offsetY;

      userHeights.forEach((userHeight, index) => {
        if (
          currentHeight + projectRowOffset(offsetY) < offsetY &&
          currentHeight + userHeight + projectRowOffset(offsetY) > offsetY
        ) {
          this.userIndexStart = index;

          let calculatedOffsetX = 0;
          let dateIteration = 0;

          while (calculatedOffsetX < offsetX + $("#planner-scroll-list").scrollLeft()) {
            calculatedOffsetX += dateIteration % 7 < 5 ? dayWidth : weekendWidth;
            dateIteration++;
          }

          this.userDateStart = OffsetDays(useDateStore.getState().date, dateIteration - 1);
        }

        currentHeight += userHeight;
      });
    }
  }

  getCreationProject(e, groupedProjectUsers, bookingFormat, shownProjects, activeProjects, projects) {
    const userHeights = groupedProjectUsers.map((array) =>
      array
        .map((user) => (usersMaxScheduleTime(user) / 60) * this.formatMapping[bookingFormat] + 17)
        .reduce((partialSum, a) => partialSum + a, 50)
    );

    userHeights[0] += 250;

    const offsetY = e.pageY + document.querySelector("#planner-scroll-list")?.scrollTop;

    let offsetHeight = 0;
    let selectedProject;

    userHeights.forEach((height, i) => {
      if (!selectedProject && (offsetY < height + offsetHeight || i + 1 === userHeights.length)) {
        selectedProject = shownProjects[i];
      }

      offsetHeight += height;
    });

    if (selectedProject?.active) {
      return selectedProject;
    } else {
      return activeProjects.find((pr) => pr.active) || projects.find((pr) => pr.active);
    }
  }
}

export default GroupedPlannerActions;
