import React, { useState } from "react";
import "./my_week.scss";
import moment from "moment";
import "moment/locale/de";
import "moment/locale/fr";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { WhiteTooltip } from "../default_components/Tooltips";
import CalendarInegrationDialog from "./modals/CalendarInegrationDialog";
import { TeambookIcon } from "../default_images/TeambookIcon";
import { icons } from "../default_images/IconsList";
import TeambookDatePicker from "../default_components/teambook_date_selector/DatePicker";
import { useAccountStore } from "../../stores/accountStore";
import { shallow } from "zustand/shallow";
import { useProfileStore } from "../../stores/profileStore";
import { TodayButton } from "../default_components/TodayButton";

const MyWeekControlBar = ({ date, changeDate }) => {
  const { t } = useTranslation();
  const [calendarIntegrationOpen, setCalendarIntegrationOpen] = useState(false);
  const [user] = useProfileStore((state) => [state.profile], shallow);
  const [account] = useAccountStore((state) => [state.account], shallow);

  const clickOnToday = () => {
    changeDate(DateTime.now().startOf("week"));
  };

  if (account) {
    moment.updateLocale("en", {
      week: {
        dow: account.first_week_day === "Monday" ? 1 : 0,
      },
    });
  }

  return (
    <div className="my-week__control-bar">
      <TodayButton setDate={clickOnToday} />

      <TeambookDatePicker
        style={{ marginLeft: 20 }}
        from={date}
        formatStart={"MMM yyyy"}
        onDayClick={(date) => {
          changeDate(date.startOf("week"));
        }}
        onArrowClicks={{
          left: () => {
            changeDate(date.minus({ days: 7 }).startOf("week"));
          },
          right: () => {
            changeDate(date.plus({ days: 7 }).startOf("week"));
          },
        }}
        range={1}
        closeOnSelect={true}
        setFrom={changeDate}
        type="default"
        highlight="today"
        arrowColor="darkblue_new"
        disabledRanges={["1_year"]}
      />

      <div onClick={() => setCalendarIntegrationOpen(true)} className="my-week__calendar-integration">
        <TeambookIcon name={icons.CALENDAR} color="darkblue_new" />
        <p>{t("my_week.calendar_integration")}</p>
      </div>

      <CalendarInegrationDialog open={calendarIntegrationOpen} setOpen={setCalendarIntegrationOpen} user={user} />
    </div>
  );
};

export default MyWeekControlBar;
