import Header from "../header/Header";
import React, { useEffect, useState, useRef } from "react";
import ControlBar from "./ControlBar";
import UserPlanning from "./users_planning/UserPlanning";
import "./capacity_planning.scss";
import ProjectPlanning from "./projects_planning/ProjectPlanning";
import { DateTime } from "luxon";
import Api from "../../Api";
import lsKeys from "../default_values/defaultKeys";
import DidYouKnow from "../planner/default_components/DidYouKnow";
import OnboardingComponent from "../planner/onboarding/OnboardingComponent";
import { useTranslation } from "react-i18next";
import { useProfileStore } from "../../stores/profileStore";
import { shallow } from "zustand/shallow";
import { useNotificationStore } from "../../stores/notificationStore";
import { UserFeedbackBanner } from "../default_components/UserFeedbackBanner";
import { SwitchToPaidPlanBanner } from "../default_components/SwitchToPaidPlanBanner";
import { useNavigate } from "react-router-dom";

export const getCorrectTextLocale = (string, t, totalDays = undefined, isUserOver = false) => {
  let statusString = "";

  switch (string) {
    case "yellow-bg": {
      statusString = t(`planning.capacity.below_capacity`);
      break;
    }
    case "red-bg": {
      if (isUserOver) {
        statusString = t(`planning.capacity.overlap_end`);
      } else {
        statusString = t(`planning.capacity.over_capacity`);
      }
      break;
    }
    case "INF": {
      statusString = t(`planning.capacity.not_planner_yet`);
      break;
    }
    default:
      break;
  }

  if (totalDays) {
    if (statusString === "") {
      statusString += `User Total ${Math.ceil(totalDays)} d`;
    } else {
      statusString += `, Total ${Math.ceil(totalDays)} d`;
    }
  }

  return statusString;
};

export default ({ type = localStorage.getItem(lsKeys.LAST_CAPACITY_TYPE) || "user" }) => {
  const navigate = useNavigate();
  const [setInAppNotification] = useNotificationStore((state) => [state.setInAppNotification], shallow);

  const [selectedType, setSelectedType] = useState(type);
  const [selectedDate, setSelectedDate] = useState(
    DateTime.now().startOf("month").setLocale(localStorage.getItem(lsKeys.LANGUAGE))
  );
  const [users, setUsers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [teams, setTeams] = useState([]);
  const [tags, setTags] = useState([]);
  const [clients, setClients] = useState([]);
  const [managers, setManagers] = useState([]);
  const [businessUnits, setBusinessUnits] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [selectedProject, setSelectedProject] = useState();
  const [selectedViewType, setSelectedViewType] = useState(
    parseInt(localStorage.getItem(lsKeys.LAST_CAPACITY_ZOOM)) || 12
  );
  const didYouKnowRef = useRef({});
  const [onboardingOpened, setOnboardingOpened] = useState(
    localStorage.getItem(lsKeys.CAPACITY_ONBOARDING_OPENED) === "true"
  );
  const { t } = useTranslation();
  const [copyPlannerOpen, setCopyPlannerOpen] = useState(false);

  const [profile] = useProfileStore((state) => [state.profile], shallow);

  useEffect(() => {
    Api.Users.all().then((res) => {
      const fetchedUsers = res.data.filter((user) => user.team_ids.length > 0).filter((user) => user.active);
      setUsers(fetchedUsers);
    });

    Api.Projects.minimal().then((res) => {
      setProjects(res.data.filter((proj) => proj.active || proj.code === "PUB"));

      Api.Projects.managers().then((res) => {
        setManagers(res.data);
      });

      Api.Projects.business_units().then((res) => {
        setBusinessUnits(res.data.business_units);
      });
    });

    Api.Teams.minimal().then((res) => setTeams(res.data));
    Api.Tags.all().then((res) => setTags(res.data));
    Api.Clients.all().then((res) => setClients(res.data));

    // localStorage.setItem(lsKeys.LAST_PLANNER_TYPE, "capacity_planning");
  }, []);

  useEffect(() => {
    navigate(`/capacity/${selectedType}`);
  }, [selectedType]);

  useEffect(() => {
    setSelectedType(type);
  }, [type]);

  const planningComponent = () => {
    switch (selectedType) {
      case "user":
        return (
          <UserPlanning
            date={selectedDate}
            users={users}
            teams={teams}
            projects={projects}
            tags={tags}
            jumpToProject={jumpToProject}
            selectedUser={selectedUser}
            selectedViewType={selectedViewType}
            copyPlannerOpen={copyPlannerOpen}
            setCopyPlannerOpen={setCopyPlannerOpen}
            copyPlanner={copyPlanner}
          />
        );
      case "project":
        return (
          <ProjectPlanning
            date={selectedDate}
            allProjects={projects}
            clients={clients}
            users={users}
            jumpToUser={jumpToUser}
            selectedProject={selectedProject}
            selectedViewType={selectedViewType}
          />
        );
    }
  };

  const changeSelectedType = (newType) => {
    localStorage.setItem(lsKeys.LAST_CAPACITY_TYPE, newType);
    setSelectedType(newType);
  };

  const jumpToUser = (userId) => {
    setSelectedUser(userId);
    localStorage.setItem(lsKeys.LAST_CAPACITY_TYPE, "user");
    setSelectedType("user");
  };

  const jumpToProject = (projectId) => {
    setSelectedProject(projectId);
    localStorage.setItem(lsKeys.LAST_CAPACITY_TYPE, "project");
    setSelectedType("project");
  };

  const copyPlanner = () => {
    setCopyPlannerOpen(true);
  };

  if (["reporting_projects", "reporting_users", "reporting_tags"].includes(selectedType)) {
    setInAppNotification(
      <div style={{ display: "flex", flexDirection: "column" }}>
        <p> {t("moved_feature")}</p>
        <a href={"/dashboard/reporting"}>{"web.teambookapp.com/dashboard/reporting"}</a>
      </div>
    );
  }

  return (
    <div className="capacity-planning">
      <Header setOnboardingOpened={setOnboardingOpened} didYouKnowRef={didYouKnowRef} page={"planning"} />

      <ControlBar
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        changeSelectedType={changeSelectedType}
        selectedType={selectedType}
        users={users}
        teams={teams}
        projects={projects}
        clients={clients}
        tags={tags}
        businessUnits={businessUnits}
        managers={managers}
        setSelectedViewType={setSelectedViewType}
        selectedViewType={selectedViewType}
        copyPlanner={copyPlanner}
      />

      {profile && <DidYouKnow ref={didYouKnowRef} />}

      <OnboardingComponent
        page="capacity"
        onboardingOpened={onboardingOpened}
        setOnboardingOpened={setOnboardingOpened}
        role={localStorage.getItem(lsKeys.ROLE || "regular")}
      />

      {planningComponent()}

      {profile && <UserFeedbackBanner />}
      {profile && <SwitchToPaidPlanBanner />}
    </div>
  );
};
