import React from "react";
import "./my_week.scss";
import { defaultProjectIcons, plannerIcons } from "../default_values/DefaultProjectIcons";
import { useTranslation } from "react-i18next";
import { WhiteTooltip } from "../default_components/Tooltips";
import { TeambookIcon } from "../default_images/TeambookIcon";
import { icons } from "../default_images/IconsList";
import lsKeys from "../default_values/defaultKeys";
import { bookingStartTimeText } from "../planner/default_components/planner_right_side_modal/StartTimeBlock";

const BookingIcons = ({ booking, color, project, iconColor = "darkblue_new" }) => {
  const { t } = useTranslation();

  const linkToAnchor = (text) => {
    let urlRegex = /(?:(?:https?|ftp):\/\/)?[\w/\-?=%.]+\.[\w/\-&?=%.]+/gi;
    let httpRegex = /^(http|https):/;
    return (text = text.replace(
      urlRegex,
      (url) => `<a target='_blank' rel="noopener noreferrer" href=${httpRegex.test(url) ? url : `//${url}`}>${url}</a>`
    ));
  };

  const getColor = () => {
    const isDarkTheme = localStorage.getItem(lsKeys.THEME) === "dark";

    if (isDarkTheme) {
      return booking.tentative ? "#FFFFFF" : "#1A1B23";
    } else {
      return booking.tentative ? "#FFFFFF" : "#474747";
    }
  };

  return (
    <div className="booking__icons-container">
      {project.kind === "time_off" && plannerIcons(project.icon_id, getColor(), 0)}
      {booking.repetition && <TeambookIcon name={icons.BOOKING_REPETITION} color={iconColor} />}
      {booking.location === 1 && <TeambookIcon name={icons.BOOKING_ON_CLIENT_SITE} color={iconColor} />}
      {booking.location === 2 && <TeambookIcon name={icons.BOOKING_AT_HOME} color={iconColor} />}

      {booking.comment.length > 0 && (
        <WhiteTooltip
          title={
            <span
              dangerouslySetInnerHTML={{
                __html: linkToAnchor(booking.comment),
              }}
            />
          }
        >
          <TeambookIcon name={icons.BOOKING_COMMENT} color={iconColor} />
        </WhiteTooltip>
      )}

      {booking.using_budget === false && <TeambookIcon name={icons.BOOKING_NON_BILLABLE} color={iconColor} />}
    </div>
  );
};

export default BookingIcons;
