import { shallow } from "zustand/shallow";
import { getCellWidthForZoom, GetDaysInZoom, GetStartDateForPlannerDate, OffsetDays } from "../../../lib/DateUtils";
import { useDateStore, useZoomStore } from "../../../stores/planner";
import { DateTime } from "luxon";
import lsKeys from "../../default_values/defaultKeys";
import { PlannerContext } from "../context/PlannerContext";
import { useContext } from "react";

export const MonthName = ({ zIndex, page }) => {
  const [zoom] = useZoomStore((state) => [state.zoom], shallow);
  const [date] = useDateStore((state) => [state.date], shallow);
  const { weekendsHidden, groupClients } = useContext(PlannerContext);
  const lang = localStorage.getItem(lsKeys.LANGUAGE);

  let initialOffset = 161;

  if (groupClients && page === "grouped") {
    initialOffset += 50;
  }

  const firstPlannerDate = GetStartDateForPlannerDate(date, zoom);

  let leftOffset = 0;
  let days = GetDaysInZoom(zoom);

  const months = Math.ceil(
    Math.abs(
      DateTime.fromJSDate(firstPlannerDate)
        .startOf("month")
        .diff(DateTime.fromJSDate(OffsetDays(firstPlannerDate, days)).endOf("month"), "months")
        .toObject().months
    )
  );

  return Array.from({ length: months }, (v, k) => {
    if (k === 0) {
      return DateTime.fromJSDate(firstPlannerDate);
    } else {
      return DateTime.fromJSDate(firstPlannerDate).plus({ month: k }).startOf("month");
    }
  }).map((month, index) => {
    let endOfMonth = month.endOf("month");

    if (index === months - 1) {
      endOfMonth = DateTime.fromJSDate(OffsetDays(firstPlannerDate, days)).minus({ days: 1 });
    }

    let monthWidth = 0;

    const daysRemaining = Math.ceil(endOfMonth.diff(month, "days").days);

    Array.from({ length: daysRemaining }).forEach((value, index) => {
      let newDate = month.plus({ days: index });

      if (weekendsHidden && [6, 7].includes(newDate.weekday)) {
        monthWidth += 10;
        leftOffset += 10;
      } else {
        monthWidth += getCellWidthForZoom(zoom);
        leftOffset += getCellWidthForZoom(zoom);
      }
    });

    const calculateMonthStyles = () => {
      if (zoom === 90) {
        return {
          position: "relative",
          visibility: daysRemaining < 7 && "hidden",
        };
      } else {
        return {
          left: initialOffset,
          position: "sticky",
          right: 30,
        };
      }
    };

    return (
      <div
        className="date-row__month"
        style={{ left: initialOffset + leftOffset - monthWidth, width: monthWidth, zIndex: zIndex }}
      >
        {daysRemaining >= 5 && <p style={calculateMonthStyles()}>{month.setLocale(lang).toFormat("MMMM")}</p>}
      </div>
    );
  });
};
