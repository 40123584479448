import { create } from "zustand";
import Api from "../Api";

export const useAccountStore = create((set) => ({
  account: null,
  fetchAccount: async () => {
    const response = await Api.Account.get();
    set({ account: await response.data });
  },
  reset: () => set({ account: null }),
}));
