import React, { useEffect, useRef, useState } from "react";
import "./settings.scss";
import "../default_styles/tb-colors.css";
import Header from "../header/Header";
import Api from "../../Api";
import SettingsLeftSide from "./sub_components/SettingsLeftSide";
import SettingsRightSide from "./sub_components/SettingsRightSide";
import { useSearchParams } from "react-router-dom";
import { useAccountStore } from "../../stores/accountStore";
import DidYouKnow from "../planner/default_components/DidYouKnow";
import { useProfileStore } from "../../stores/profileStore";
import { shallow } from "zustand/shallow";

const Settings = () => {
  const [page, setPage] = useSearchParams();

  const getPage = () => {
    switch (page.get("page")) {
      case "settings": {
        return 0;
      }
      case "time_offs": {
        return 1;
      }
      case "tasks": {
        return 2;
      }
      case "schedule": {
        return 3;
      }
      case "subscription": {
        return 4;
      }
      case "integration": {
        return 5;
      }
      case "customization": {
        return 6;
      }
      case "delete": {
        return 7;
      }
      default:
        return 0;
    }
  };

  const pages = [
    "settings",
    "time_offs",
    "tasks",
    "schedule",
    "subscription",
    "integration",
    "customization",
    "delete",
  ];
  const [users, setUsers] = useState([]);
  const [menuIndex, setMenuIndex] = useState(getPage());
  const [account, fetchAccount] = useAccountStore((state) => [state.account, state.fetchAccount]);
  const [profile] = useProfileStore((state) => [state.profile], shallow);

  const didYouKnowRef = useRef({});

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    setPage({ page: pages[menuIndex] });
  }, [menuIndex]);

  const getUsers = () => {
    Api.Users.all().then((response) => setUsers(response.data));
  };

  const updateAccountRequest = (values) => {
    Api.Account.update({
      account: {
        name: values.accountName,
        default_booking_duration: values.defaultBookingTime * 60,
        time_zone: values.timeZone,
        first_week_day: values.firstWeekDay,
        date_format: values.dateFormat,
        schedule: values.schedule,
      },
    })
      .then((response) => analyzeUpdateResponse(response))
      .catch((error) => console.log(error));
  };

  const analyzeUpdateResponse = (response) => {
    if (response.status === 200) {
      fetchAccount();
    } else {
      // window.location.reload();
    }
  };

  const deleteAccountRequest = () => {
    Api.Account.delete();
    logUserOut();
  };

  const logUserOut = () => {
    localStorage.removeItem("Auth-Token");
    window.location.replace("/register");
  };

  if (account?.id === undefined) {
    return <div />;
  }

  return (
    <div className="row settings_page" style={{ margin: "0", justifyContent: "center" }}>
      <Header didYouKnowRef={didYouKnowRef} />

      <div className="row settings-page__container">
        <SettingsLeftSide menuIndex={menuIndex} setMenuIndex={setMenuIndex} />

        <SettingsRightSide
          menuIndex={menuIndex}
          updateAccountRequest={updateAccountRequest}
          users={users}
          deleteAccountRequest={deleteAccountRequest}
        />
      </div>

      {profile && <DidYouKnow ref={didYouKnowRef} />}
    </div>
  );
};

export default Settings;
